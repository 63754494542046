import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '@env';
import { SharedModule } from '@shared/shared.module';

import { CoreModule } from './core';
import { AppStoreModule } from './store';
import {
  HomeHeaderComponent,
  HomeLayoutComponent,
  AppLayoutComponent,
  AppFooterComponent,
  AppHeaderComponent,
  NavigationComponent,
  NavAccountComponent,
  NotificationBarComponent,
  BreadcrumbComponent
} from './layout';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PrivateTopNavbarComponent } from './layout/navigation/private-top-navbar/private-top-navbar.component';
import { MaintenanceComponent } from './layout/maintenance/maintenance.component';

@NgModule({
  imports: [
    CoreModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    AppStoreModule,
    !environment.production ? StoreDevtoolsModule.instrument() : []
  ],
  declarations: [
    AppComponent,
    HomeHeaderComponent,
    HomeLayoutComponent,
    AppHeaderComponent,
    AppLayoutComponent,
    AppFooterComponent,
    NavigationComponent,
    NavAccountComponent,
    NotificationBarComponent,
    BreadcrumbComponent,
    PrivateTopNavbarComponent,
    MaintenanceComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
