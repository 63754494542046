import { environment } from '@env';

export const APP_ROUTES = {
  HOME: 'home',
  PROJECT: 'project',
  PROJECT_SEARCH: 'search',
  SIGN_IN: 'auth/sign-in',
  SIGN_UP: `${environment.awsCognito.signUp.url}?client_id=${environment.awsCognito.signUp.clientId}`,
  CONFIRM: 'auth/confirm',
  WELCOME: 'welcome',
  PROFILE: 'my-account/profile',
  MYVVID_PROFILE_URL: `https://${
    environment.awsCognito.amplify.Auth.oauth.domain
  }/autoredirect?continue=${encodeURIComponent('/my-profile/preferences')}`,
  SOCIAL_NETWORKS: 'my-account/social-networks',
  MY_PROJECTS: 'my-account/projects',
  TERMS: '/legal/terms-and-conditions',
  PRIVACY: '/legal/privacy-policy'
};

export const LOGO_URL = {
  DEFAULT: '/assets/icons/svg/vv-logo-hivve.svg',
  ALTERNATIVE: '/assets/icons/svg/track_impact_logo_hivve.svg',
  TRACK_IMPACT: '/assets/icons/svg/track-impact.svg'
};

export const EXTERNAL_URL = {
  VV_OFFICIAL: 'https://www.vertigoventures.com/register/trackimpact/',
  VV_ABOUT_US: 'https://www.vertigoventures.com/impact-experts/',
  VV_CONTACT_US: 'https://www.vertigoventures.com/contact/',
  VV_URL: 'http://www.vertigoventures.com/',
  TI_URL: 'https://trackimpact.org/',
  VV_TERMS: 'https://www.vertigoventures.com/terms-and-condition/',
  VV_TERMS_PDF:
    'https://f.hubspotusercontent20.net/hubfs/8102620/Website/Downloads/2021/VV%20Terms%20&%20Conditions.pdf',
  ALL_ABOUT_COOKIES: 'http://www.allaboutcookies.org/manage-cookies/',
  HIVVE_MAILTO: 'mailto:hello@hivve.tech',
  GOOGLE_PARTNERS: 'https://www.google.com/policies/privacy/partners/',
  GOOGLE_ANALYTICS: 'https://tools.google.com/dlpage/gaoptout/',
  ICO_URL: 'https://ico.org.uk/concerns/',
  VV_POLICY: 'https://www.vertigoventures.com/privacy-policy/',
  VV_DEMO_FORM: 'https://www.vertigoventures.com/demo-form-impacttracker/',
  VV_IT_LOGIN: 'https://www.vertigoventures.com/products/impacttracker-impact-software/login/',
  VV_PRIVACY_POLICY: 'https://www.vertigoventures.com/#!privacy-policy/ucrbj',
  TI_HIVVE_MAILTO: 'mailto:trackimpact@hivve.tech',
  MYVVID: 'https://myvvid.com',
  MYVVID_ENV_URL: `http://${environment.env}-myvvid.com`,
  HIVVE_PRODUCTS_IT: 'https://hivve.tech/products/impactecosystem/impacttracker/',
  IT_AUTO_LOGIN_ENV_URL: `https://${environment.env}-vvimpacttracker.com?autologin=true`
};

export const MIN_DATE = {
  DATE: new Date('0001-01-01')
};

export const PRODUCT_NAME = {
  IMPACT_TRACKER: 'ImpactTracker',
  TRACK_IMPACT: 'TrackImpact',
  IMPACT_ACADEMY: 'ImpactAcademy',
  IMPACT_TRACKER_PLUS: 'ImpactTracker+'
};
