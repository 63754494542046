import { Component, Input, OnInit } from '@angular/core';
import { MILESTONES_PROGRESS_UPPER_BOUNDS } from '@app/constants';

@Component({
  selector: 'app-progress-gauge',
  templateUrl: './progress-gauge.component.html',
  styleUrls: ['./progress-gauge.component.scss']
})
export class ProgressGaugeComponent implements OnInit {
  @Input() progress!: number;

  get progressPercent(): string {
    // todo what is the format of the progress value?
    // * that will decide if we use the Percent pipe at all
    return `${this.progress}%`;
  }

  get progressColor(): string {
    const currentBound = MILESTONES_PROGRESS_UPPER_BOUNDS.find(
      (upperBound) => this.progress <= upperBound.percent
    );

    return currentBound ? currentBound.color : '';
  }

  constructor() {}

  ngOnInit(): void {}
}
