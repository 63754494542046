import { Component, OnInit } from '@angular/core';
import { APP_ROUTES, EXTERNAL_URL } from '@app/constants';
import { INavItem } from '@app/models';
import { version } from 'package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {
  copyright = `Copyright © ${new Date().getFullYear()} hivve Group Ltd. All rights reserved.`;
  footerMenu: INavItem[] = [
    { label: 'Projects', routerLink: `/${APP_ROUTES.PROJECT_SEARCH}` },
    { label: 'Start Tracking', href: EXTERNAL_URL.VV_OFFICIAL, target: '_blank' },
    { label: 'About us', href: EXTERNAL_URL.VV_ABOUT_US, target: '_blank' },
    { label: 'Contact us', href: EXTERNAL_URL.VV_CONTACT_US, target: '_blank' }
  ];

  footerLinks: INavItem[] = [
    { label: 'Privacy Policy', routerLink: 'legal/privacy-policy' },
    { label: 'Terms and Conditions', routerLink: 'legal/terms-and-conditions' }
  ];

  get version(): string {
    return `v.${version}`;
  }

  constructor() {}

  ngOnInit(): void {}
}
