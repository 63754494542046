import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { environment } from '@env';
import { APP_ROUTES } from '@app/constants';

@Injectable()
export class FlowControlService {
  constructor(private router: Router) {}

  validateAccessCode(code: string): Observable<boolean> {
    const delayMs = 2000;
    const valid = new BehaviorSubject<boolean>(false);

    if (code === environment.appAccessCode) {
      valid.next(true);
      localStorage.setItem('userAccessCode', environment.appAccessCode);

      setTimeout(() => {
        this.router.navigate([APP_ROUTES.HOME]);
      }, delayMs);
    }

    return valid.pipe(delay(delayMs));
  }
}
