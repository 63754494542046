import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import { APP_ROUTES } from '@app/constants';

@Injectable()
export class UnAuthGuard implements CanActivate {
  constructor(private auth: AuthClass, private router: Router) {}

  canActivate(): Promise<boolean> {
    return this.auth
      .currentAuthenticatedUser()
      .then(() => {
        this.router.navigate([APP_ROUTES.PROFILE]);
        return false;
      })
      .catch(() => {
        return true;
      });
  }
}
