<div class="team-avatars_container">
  <p class="user-avatars_title">{{ label }}</p>
  <div class="team-avatars_wrapper d-inline-flex align-items-center">
    <ng-container *ngFor="let userProfile of userProfileList; let i = index">
      <app-default-avatar
        class="user-avatars_wrapper"
        [style.z-index]="userProfileList.length - i"
        [userProfile]="userProfile"
      ></app-default-avatar>
    </ng-container>
    <div *ngIf="count > 3">
      <span class="user-avatars_count"><i class="icon icon-people"></i>{{ count }}</span>
    </div>
  </div>
</div>
