import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ROUTES } from '@app/constants';
import { MaintenanceService } from '@app/services';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  constructor(private maintenanceService: MaintenanceService, private router: Router) {}

  ngOnInit(): void {
    if (!this.maintenanceService.isMaintenanceMode()) {
      this.router.navigate([APP_ROUTES.HOME]);
    }
  }
}
