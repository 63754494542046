import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-password-strength-meter',
  templateUrl: './password-strength-meter.component.html',
  styleUrls: ['./password-strength-meter.component.scss']
})
export class PasswordStrengthMeterComponent {
  @Input() passwordControl!: FormControl;

  get password(): string {
    return this.passwordControl?.value ? this.passwordControl.value : '';
  }

  get charactersLabel(): string {
    return this.passwordControl?.value?.length !== 1 ? 'characters' : 'character';
  }
}
