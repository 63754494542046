import { createAction, props } from '@ngrx/store';
import { registerAction } from '@app/utils';
import { ProjectStats } from '@app/models/api';
import { IAccount, IProject, SearchParams } from '@app/models';

export class SearchResultActionTypes {
  static readonly noOpAction = registerAction('[Search Result] No Operation Action');
  static readonly loadSearchResult = registerAction('[Search Result] Load Search Results');
  static readonly loadSearchResultSuccess = registerAction(
    '[Search Result] Load Search Results Success'
  );
  static readonly loadSearchResultError = registerAction(
    '[Search Result] Load Search Results Error'
  );

  static readonly clearAppliedFilters = registerAction('[Search Result] Clear Applied Filters');

  static readonly followSearchResultProject = registerAction('[Search Result] Follow Project');
  static readonly followSearchResultProjectSuccess = registerAction(
    '[Search Result] Follow Project Success'
  );
  static readonly followSearchResultProjectError = registerAction(
    '[Search Result] Follow Project Error'
  );

  static readonly setTerm = registerAction('[Search Result] Set term/keywords value');

  static readonly setCurentPage = registerAction('[Search Result] Set current page value');

  static readonly clearCurrentState = registerAction('[Search Result] Clear current state');

  static readonly toggleLikedProject = registerAction('[Search Result] Toggle Project Like');
  static readonly toggleLikedProjectSuccess = registerAction(
    '[Search Result] Toggle Project Like Success'
  );
  static readonly toggleLikedProjectError = registerAction(
    '[Search Result] Toggle Project Like Error'
  );
}
export const noOptAction = createAction(SearchResultActionTypes.noOpAction);

export const loadSearchResult = createAction(
  SearchResultActionTypes.loadSearchResult,
  props<{ searchParams: SearchParams }>()
);
export const loadSearchResultSuccess = createAction(
  SearchResultActionTypes.loadSearchResultSuccess,
  props<{
    projects: IProject[];
    filters: ProjectStats;
    searchParams: SearchParams;
    totalRecords: number;
  }>()
);
export const loadSearchResultError = createAction(
  SearchResultActionTypes.loadSearchResultError,
  props<{ error: string }>()
);

export const clearAppliedFilters = createAction(SearchResultActionTypes.clearAppliedFilters);

export const toggleFollowSearchResultProject = createAction(
  SearchResultActionTypes.followSearchResultProject,
  props<{ project: IProject; region: string; following: boolean }>()
);
export const followSearchResultProjectSuccess = createAction(
  SearchResultActionTypes.followSearchResultProjectSuccess,
  props<{ followers: IAccount[]; followersCount: number; project: IProject; isFollow: boolean }>()
);
export const followSearchResultProjectError = createAction(
  SearchResultActionTypes.followSearchResultProjectError,
  props<{ error: string }>()
);

export const setCurrentPage = createAction(
  SearchResultActionTypes.setTerm,
  props<{ currentPage: number }>()
);

export const clearCurrentState = createAction(
  SearchResultActionTypes.clearCurrentState,
  props<{ projects: IProject[] }>()
);

export const toggleLikedProject = createAction(
  SearchResultActionTypes.toggleLikedProject,
  props<{ projectId: number; region: string; isLiked: boolean }>()
);

export const toggleLikedProjectSuccess = createAction(
  SearchResultActionTypes.toggleLikedProjectSuccess,
  props<{ likesCount: number; projectId: number | string }>()
);
export const toggleLikedProjectError = createAction(
  SearchResultActionTypes.toggleLikedProjectError,
  props<{ error: string }>()
);
