import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import { APP_ROUTES } from '@app/constants';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthClass, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.auth
      .currentAuthenticatedUser()
      .then(() => {
        // redirect to returnUrl route if user is authenticated
        const returnUrl = sessionStorage.getItem('returnUrl');

        if (returnUrl) {
          sessionStorage.removeItem('returnUrl');
          this.router.navigateByUrl(returnUrl);
        }

        return true;
      })
      .catch((error) => {
        console.log(error);
        sessionStorage.setItem('returnUrl', state.url);
        this.router.navigate([APP_ROUTES.SIGN_IN]);
        return false;
      });
  }
}
