import { createAction, props } from '@ngrx/store';

import { registerAction } from '@app/utils';
import { IAccount, IProject } from '@app/models';

class UserDetailsActions {
  static readonly loadUserDetails = registerAction('[User Details] Load User Details');
  static readonly loadUserDetailsSuccess = registerAction(
    '[User Details] Load User Details Success'
  );
  static readonly loadUserDetailsError = registerAction('[User Details] Load User Details Error');
  static readonly updateUserProfileError = registerAction(
    '[User Details] Update User Profile Error'
  );
  static readonly clearUserDetails = registerAction('[User Details] Clear User Details');
  static readonly toggleProjectFollow = registerAction('[User Details] Toggle Project Following');

  static readonly loadFollowedProjects = registerAction('[User Details] Load Followed Projects');
  static readonly loadFollowedProjectsSuccess = registerAction(
    '[User Details] Load Followed Projects Success'
  );
  static readonly loadFollowedProjectsError = registerAction(
    '[User Details] Load Followed Projects Error'
  );

  static readonly loadLikedProjects = registerAction('[User Details] Load Liked Projects');
  static readonly loadLikedProjectsSuccess = registerAction(
    '[User Details] Load Liked Projects Success'
  );
  static readonly loadLikedProjectsError = registerAction(
    '[User Details] Load Liked Projects Error'
  );

  static readonly loadCollaborationProjects = registerAction(
    '[User Details] Load Collaboration Projects'
  );
  static readonly loadCollaborationProjectsSuccess = registerAction(
    '[User Details] Load Collaboration Projects Success'
  );
  static readonly loadCollaborationProjectsError = registerAction(
    '[User Details] Load Collaboration Projects Error'
  );

  static readonly loadUserProjects = registerAction('[User Details] Load User Projects');
  static readonly loadUserProjectsSuccess = registerAction(
    '[User Details] Load User Projects Success'
  );
  static readonly loadUserProjectsError = registerAction('[User Details] Load User Projects Error');

  static readonly toggleLikedProject = registerAction('[User Details] Toggle Project Likes');
}

export const loadUserDetails = createAction(
  UserDetailsActions.loadUserDetails,
  props<{ username: string }>()
);
export const loadUserDetailsSuccess = createAction(
  UserDetailsActions.loadUserDetailsSuccess,
  props<{ details: IAccount }>()
);
export const loadUserDetailsError = createAction(
  UserDetailsActions.loadUserDetailsError,
  props<{ error: string }>()
);
export const updateUserProfileError = createAction(
  UserDetailsActions.updateUserProfileError,
  props<{ error: string }>()
);

export const clearUserDetails = createAction(UserDetailsActions.clearUserDetails);

export const toggleProjectFollow = createAction(
  UserDetailsActions.toggleProjectFollow,
  props<{ project: IProject; isFollow: boolean }>()
);

export const loadFollowedProjects = createAction(UserDetailsActions.loadFollowedProjects);
export const loadFollowedProjectsSuccess = createAction(
  UserDetailsActions.loadFollowedProjectsSuccess,
  props<{ projects: IProject[] }>()
);
export const loadFollowedProjectsError = createAction(
  UserDetailsActions.loadFollowedProjectsError,
  props<{ error: string }>()
);

export const loadLikedProjects = createAction(UserDetailsActions.loadLikedProjects);
export const loadLikedProjectsSuccess = createAction(
  UserDetailsActions.loadLikedProjectsSuccess,
  props<{ projects: IProject[] }>()
);
export const loadLikedProjectsError = createAction(
  UserDetailsActions.loadLikedProjectsError,
  props<{ error: string }>()
);

export const loadCollaborationProjects = createAction(UserDetailsActions.loadCollaborationProjects);
export const loadCollaborationProjectsSuccess = createAction(
  UserDetailsActions.loadCollaborationProjectsSuccess,
  props<{ projects: IProject[] }>()
);
export const loadCollaborationProjectsError = createAction(
  UserDetailsActions.loadCollaborationProjectsError,
  props<{ error: string }>()
);

export const loadUserProjects = createAction(UserDetailsActions.loadUserProjects);
export const loadUserProjectsSuccess = createAction(
  UserDetailsActions.loadUserProjectsSuccess,
  props<{ projects: IProject[] }>()
);
export const loadUserProjectsError = createAction(
  UserDetailsActions.loadUserProjectsError,
  props<{ error: string }>()
);

export const toggleLikedProject = createAction(
  UserDetailsActions.toggleLikedProject,
  props<{ projectId: number }>()
);
