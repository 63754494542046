import { NgModule } from '@angular/core';

import { AnnotationsService, CircularGaugeModule } from '@syncfusion/ej2-angular-circulargauge';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { PagerModule } from '@syncfusion/ej2-angular-grids';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import {
  CountService,
  HtmlEditorService,
  LinkService,
  RichTextEditorModule,
  ToolbarService
} from '@syncfusion/ej2-angular-richtexteditor';

@NgModule({
  exports: [CircularGaugeModule, TabModule, PagerModule, RichTextEditorModule, UploaderModule],
  providers: [AnnotationsService, ToolbarService, LinkService, HtmlEditorService, CountService]
})
export class SyncfusionModule {}
