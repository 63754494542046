<footer class="footer">
  <nav class="navbar navbar-expand-sm bg-transparent flex-wrap flex-md-row">
    <div class="col-12 xol-xl-2 col-md-2 me-md-4 ms-md-0 text-center">
      <span class="copyright">{{ copyright }}</span>
    </div>
    <div class="col-12 col-xl-2 col-md-3 me-md-4 ms-md-0 text-center">
      <a *ngFor="let link of footerLinks" class="footer-link" [routerLink]="link.routerLink">{{
        link.label
      }}</a>
    </div>
    <ul
      class="
        col-12 col-xl-4 col-md-5
        navbar-nav
        me-auto
        flex-wrap
        external-links
        flex-row
        justify-content-center
      "
    >
      <li class="nav-item d-inline" *ngFor="let link of footerMenu">
        <a
          *ngIf="link.routerLink"
          class="nav-link"
          [routerLink]="link.routerLink"
          [target]="link.target || ''"
          >{{ link.label }}</a
        >
        <a *ngIf="link.href" class="nav-link" [href]="link.href" [target]="link.target || ''">{{
          link.label
        }}</a>
      </li>
    </ul>
    <div class="col-12 col-xl-1 col-md-1 me-md-4 ms-md-0 version text-center">
      <span class="copyright">{{ version }}</span>
    </div>
  </nav>
</footer>
