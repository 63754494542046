import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateRange'
})
export class DateRangePipe implements PipeTransform {
  transform(value: { startDate: string; endDate: string }): string {
    let displayValue = '';

    if (value.startDate && value.endDate) {
      const start = formatDate(value.startDate, 'longDate', 'en-us');
      const end = formatDate(value.endDate, 'longDate', 'en-us');

      displayValue = `${start} - ${end}`;
    } else if (value.startDate) {
      displayValue = formatDate(value.startDate, 'longDate', 'en-us');
    } else if (value.endDate) {
      displayValue = formatDate(value.endDate, 'longDate', 'en-us');
    }

    return displayValue;
  }
}
