import {
  EVIDENCE_FILE_UPLOAD_BANNERS,
  EVIDENCE_FILE_UPLOAD_EXTENSIONS,
  MIN_DATE
} from '@app/constants';
import { PageType } from '@app/enums';
import { IWebClipping } from '@app/models';

export interface WebClippingContentTags {
  count: number;
  label: string;
  rdfTypes: [];
  score: number;
  uri: string;
}

export function mapFileUploadImageSrc(extension: string): string {
  let imgName = EVIDENCE_FILE_UPLOAD_BANNERS.generic;

  const extensionLowerCase = extension.toLowerCase();
  if (EVIDENCE_FILE_UPLOAD_EXTENSIONS.pdf.includes(extensionLowerCase)) {
    imgName = EVIDENCE_FILE_UPLOAD_BANNERS.pdf;
  }
  if (EVIDENCE_FILE_UPLOAD_EXTENSIONS.doc.includes(extensionLowerCase)) {
    imgName = EVIDENCE_FILE_UPLOAD_BANNERS.doc;
  }
  if (EVIDENCE_FILE_UPLOAD_EXTENSIONS.excel.includes(extensionLowerCase)) {
    imgName = EVIDENCE_FILE_UPLOAD_BANNERS.excel;
  }
  if (EVIDENCE_FILE_UPLOAD_EXTENSIONS.image.includes(extensionLowerCase)) {
    imgName = EVIDENCE_FILE_UPLOAD_BANNERS.image;
  }
  if (EVIDENCE_FILE_UPLOAD_EXTENSIONS.audio.includes(extensionLowerCase)) {
    imgName = EVIDENCE_FILE_UPLOAD_BANNERS.audio;
  }
  if (EVIDENCE_FILE_UPLOAD_EXTENSIONS.video.includes(extensionLowerCase)) {
    imgName = EVIDENCE_FILE_UPLOAD_BANNERS.video;
  }

  return `/assets/images/evidence/${imgName}`;
}

export function mapWebClipping(content: string): IWebClipping {
  const parsedContent = JSON.parse(content);
  const contentObject = parsedContent.objects && parsedContent.objects[0];

  const keywordList: string[] = [];
  if (contentObject?.tags) {
    contentObject.tags.forEach((value: WebClippingContentTags) => {
      keywordList.push(value.label);
    });
  }

  let publishDate = '';
  if (new Date(contentObject.date) > MIN_DATE.DATE) {
    publishDate = new Date(contentObject.date).toDateString();
  }

  const result: IWebClipping = {
    author: parsedContent.author || contentObject?.author || '',
    authorUrl: parsedContent.authorUrl || contentObject?.authorUrl || '',
    rawHtml: parsedContent.html || contentObject?.html || '',
    keywords: keywordList,

    dateAdded: publishDate,

    pageType: parsedContent.contentType || PageType.Article,
    pageUrl: parsedContent.pageUrl,
    source: parsedContent.source,
    webclippingType: parsedContent.type || contentObject?.type || 'None'
  };
  return result;
}
