export const EVIDENCE_TYPE_CODES = {
  freeText: 'txt',
  rcukData: 'rcuk',
  webClipping: 'web',
  twitter: 'tweet',
  fileUpload: 'file',
  symplecticElements: 'elements',
  email: 'email',
  other: 'other'
};

export const EVIDENCE_TYPE_BANNERS = {
  freeText: 'file-text.png',
  rcukData: 'file-ukri.png',
  webClipping: 'file-web.png',
  twitter: 'file-tweet.png',
  symplecticElements: 'file-symplectic.png',
  email: 'file-email.png',
  other: 'file-upload.png'
};

export const EVIDENCE_FILE_UPLOAD_BANNERS = {
  generic: 'file-upload.png',
  pdf: 'file-pdf.png',
  doc: 'file-doc.png',
  excel: 'file-xls.png',
  image: 'file-image.png',
  audio: 'file-audio.png',
  video: 'file-video.png'
};

export const EVIDENCE_FILE_UPLOAD_EXTENSIONS = {
  pdf: ['pdf'],
  doc: ['doc', 'docx', 'dot', 'odt', 'txt'],
  excel: ['xls', 'xlsx', 'ods'],
  image: ['jpg', 'jpeg', 'gif', 'bmp', 'png', 'tiff', 'tif', 'psd', 'ai', 'svg', 'crw', 'raw'],
  audio: ['wav', 'aif', 'aiff', 'mp3', 'm4a', 'ogg', 'wma', 'flac', 'ac3'],
  video: [
    'm4v',
    'mov',
    'avi',
    'wmv',
    'flv',
    'm2p',
    'mpg',
    'mpeg',
    '3gp',
    'vob',
    'divx',
    'mmv',
    'mp4',
    'f4v',
    '3g2',
    'vid',
    '3gp2',
    '3gpp',
    'aaf',
    'ogm',
    'ogx',
    'webm'
  ]
};

export const EVIDENCE_FILE_UPLOADS_SUPPORTED_FORMATS = {
  image: ['jpg', 'jpeg', 'gif', 'bmp', 'png'],
  specialImage: ['tiff', 'ai', 'svg'],
  video: ['mp4', 'webm'],
  audio: ['wav', 'mp3', 'ogg', 'flac'],
  pdf: ['pdf'],
  doc: ['doc', 'docx', 'txt'],
  excel: ['xls', 'xlsx']
};

// Limit 25MB for google docs library
export const EVIDENCE_FILE_SIZE_LIMIT = 25;
