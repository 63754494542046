<div class="d-flex justify-content-between p-md-0 p-4">
  <ng-container *ngIf="isLoggedIn; else showAuthLinks">
    <a class="nav-profile" [href]="getMyProfileUrl()">
      <app-default-avatar
        class="user-avatars_wrapper"
        [userProfile]="userDetails"
      ></app-default-avatar>
      <span class="px-3 name">{{ userDetails?.firstName }} {{ lastNameInitial }}</span>
    </a>

    <div class="d-inline-block">
      <button
        #popupButton
        class="btn btn-outline-secondary profile-menu-button"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        data-bs-target="#popup-menu"
        aria-expanded="false"
      >
        <i class="icon icon-caret-down"></i>
        <i class="icon icon-chevron-right"></i>
      </button>

      <ul
        #popupMenu
        [class.mobile]="showMobileVersion"
        class="popup-menu dropdown-menu my-account pt-4 mt-4 px-0"
        aria-labelledby="dropdownMenuButton1"
        id="popup-menu"
        (click)="preventBubbling($event)"
      >
        <li class="d-md-none d-flex justify-content-between border-bottom pt-0 px-4 pb-4 mb-4">
          <div class="text-primary font-type-nav-link" (click)="popupButton.click()">
            <i class="icon icon-chevron-right back-button"></i>
            Back
          </div>
          <div class="text-end">
            <button id="inner-close-button" (click)="toggleClose()" class="navbar-toggler border-0">
              <i class="icon icon-close"></i>
            </button>
          </div>
        </li>

        <li class="px-5 my-account_options">
          <div class="d-inline-flex align-items-center">
            <app-default-avatar
              class="user-avatars_wrapper user-avatars_image user-avatars_default-avatar"
              [userProfile]="userDetails"
            ></app-default-avatar>
            <div class="px-3">
              <p class="name">{{ userDetails?.firstName }} {{ lastNameInitial }}</p>
              <p class="job-title">{{ userDetails?.jobTitle }}</p>
            </div>
          </div>
        </li>

        <li
          class="px-5 my-account_item"
          [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a id="profileBtn" class="dropdown-item" [href]="getMyProfileUrl()">
            <span class="label"><i class="icon icon-user"></i></span> My Profile</a
          >
        </li>

        <li
          class="px-5 my-account_item"
          [routerLink]="APP_ROUTES.MY_PROJECTS"
          [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a class="dropdown-item"
            ><span class="label"><i class="icon icon-pie-chart"></i></span>My projects</a
          >
        </li>
      </ul>
    </div>
  </ng-container>

  <ng-template #showAuthLinks>
    <div class="auth-buttons my-2">
      <button id="signInBtn" class="btn btn-outline-primary mx-2" (click)="signIn()">
        Sign In
      </button>
      <button id="signUpBtn" class="btn btn-primary mx-2" (click)="signUp()">Register</button>
    </div>
  </ng-template>
</div>
