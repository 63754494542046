import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { DEFAULT_PROFILE_IMG, APP_ROUTES } from '@app/constants';
import { IAccount } from '@app/models';
import { AuthService } from '@app/services';
import { environment } from '@env';

@Component({
  selector: 'app-nav-account',
  templateUrl: './nav-account.component.html',
  styleUrls: ['./nav-account.component.scss']
})
export class NavAccountComponent implements AfterViewInit {
  @ViewChild('popupMenu') popupMenu!: ElementRef;
  APP_ROUTES = APP_ROUTES;
  observers: MutationObserver[] = [];

  @Input() isLoggedIn!: boolean | null;
  @Input() userDetails!: IAccount | null;
  @Output() userSignOut = new EventEmitter<void>();
  @Output() toggleDropDown: EventEmitter<void> = new EventEmitter<void>();

  showMobileVersion = false;
  env = environment;

  get profileImgUrl(): string {
    return `url(${this.userDetails?.imageUrl || DEFAULT_PROFILE_IMG})`;
  }

  get lastNameInitial(): string {
    return this.userDetails?.lastName ? `${[...this.userDetails.lastName][0]}.` : '';
  }

  constructor(private authService: AuthService, private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.showMobileVersion = window.innerWidth <= 767.98;
    this.cd.detectChanges();
  }

  signIn(): void {
    this.authService.signIn();
  }

  signUp(): void {
    window.location.replace(APP_ROUTES.SIGN_UP);
  }

  signOut(): void {
    // Temporary commented out - ITPLUS-2645
    // this.userSignOut.emit();
  }

  toggleClose(): void {
    this.toggleDropDown.emit();
  }

  preventBubbling(e: Event): void {
    e.stopPropagation();
  }

  getMyProfileUrl(): string {
    return `${APP_ROUTES.MYVVID_PROFILE_URL}`;
  }
}
