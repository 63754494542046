import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IProject } from '@app/models';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { selectIsLoadingProjects, selectLikedProjects } from '@store/selectors';
import { toggleLikedProject } from '@modules/search-result/store/actions';
import {
  selectIsLoadingLikedProject,
  selectLikesCount
} from '@modules/search-result/store/selectors';

@Component({
  selector: 'app-project-likes',
  templateUrl: './project-likes.component.html',
  styleUrls: ['./project-likes.component.scss']
})
export class ProjectLikesComponent implements OnInit {
  @Input() likesCount!: number;
  @Input() userLoggedIn!: boolean;
  @Input() project!: IProject;

  likedProjects!: IProject[];
  isLikedProject!: boolean;
  isProcessingLike = false;
  isLikeButtonActive = true;

  private stop$ = new Subject<void>();

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.pipe(select(selectLikedProjects), takeUntil(this.stop$)).subscribe((projects) => {
      if (projects) {
        this.likedProjects = projects;
        this.getIsLikedProject();
      }
    });
  }

  toggleLikedProject(): void {
    this.isProcessingLike = true;

    this.store.dispatch(
      toggleLikedProject({
        isLiked: this.userLoggedIn ? !this.isLikedProject : true,
        projectId: this.project.id,
        region: this.project.region
      })
    );

    if (this.userLoggedIn) {
      this.store
        .pipe(
          select(selectIsLoadingProjects),
          takeUntil(this.stop$),
          takeUntil(this.stop$),
          filter((project) => this.project.id === project.currentLoadingId)
        )
        .subscribe((res) => {
          if (res.currentLoadingId) {
            this.isProcessingLike = res.isLoading;
          }
        });

      this.getIsLikedProject();
    } else {
      this.store
        .pipe(
          select(selectIsLoadingLikedProject),
          takeUntil(this.stop$),
          filter((project) => this.project.id === project.currentLoadingId)
        )
        .subscribe((res) => {
          this.isProcessingLike = res.isLoadingLikedProject;
        });
    }

    this.getLikesCount();
  }

  getIsLikedProject(): void {
    const index = this.likedProjects.findIndex((res) => res.id === this.project.id);
    this.isLikedProject = index > -1 ? true : false;
  }

  getLikesCount(): void {
    this.store
      .pipe(
        select(selectLikesCount),
        takeUntil(this.stop$),
        filter((project) => this.project.id === project.currentLoadingId)
      )
      .subscribe((res) => {
        if (!this.isProcessingLike) {
          if (!this.userLoggedIn && res.likesCount > this.likesCount) {
            this.isLikedProject = true;
            this.isLikeButtonActive = false;
          }

          this.likesCount = res.likesCount;
        }
      });
  }
}
