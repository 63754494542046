import { Pipe, PipeTransform } from '@angular/core';

const KILOBYTE = 1024;
const FILE_SIZE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB'];

@Pipe({
  name: 'convertToBytes'
})
export class ConvertToBytesPipe implements PipeTransform {
  transform(value: number, targetUnit: string): number {
    const power = FILE_SIZE_UNITS.indexOf(targetUnit);

    return value * Math.pow(KILOBYTE, power);
  }
}
