import { Component, Input, OnInit } from '@angular/core';
import { ISdg } from '@app/models';
import { mapProjectSdgList } from '@app/utils';

@Component({
  selector: 'app-sdg-list',
  templateUrl: './sdg-list.component.html',
  styleUrls: ['./sdg-list.component.scss']
})
export class SdgListComponent implements OnInit {
  excessItems!: number;
  sdgDictionary!: ISdg[] | null;

  @Input()
  limitDisplay!: boolean;

  @Input()
  set sdgList(value: string[]) {
    this.totalItemNumber = value.length;
    this.sdgDictionary = mapProjectSdgList(value, this.limitDisplay);
    this.excessItems = this.totalItemNumber - this.sdgDictionary.length;
  }

  get excessItemsMessage(): string {
    return this.excessItems ? `+${this.excessItems}` : '';
  }

  private totalItemNumber!: number;

  constructor() {}

  ngOnInit(): void {}
}
