<div class="main-content">
  <div class="divider d-none d-md-block"></div>
  <ul id="product-list-icons" class="navbar-nav nav-apps prod-list-link">
    <li class="nav-item d-inline-block">
      <div class="dropdown">
        <a
          class="nav-link"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          tooltipPosition="bottom"
        >
          <i class="icon icon-candy-menu"></i>
        </a>
        <ul class="dropdown-menu bg-light apps-dropdown">
          <ng-container *ngFor="let app of apps">
            <!-- hide ImpactTracker+ icon in production -->
            <!-- <li
              class="dropdown-item"
              [ngClass]="{ disabled: disableAppForNonLicencedAccount(app) }"
              *ngIf="!(app.client_name === impactTrackerPlus && environment.production)"
            >
              <a href="{{ app.redirect_uri }}">
                <img
                  alt="{{ app.client_name }}"
                  src="{{ app.client_logo }}"
                  class="img-fluid app-logo"
                />
              </a>
            </li> -->
            <li
              class="dropdown-item"
              *ngIf="!(app.client_name === impactTrackerPlus && environment.production)"
            >
              <a href="{{ app.redirect_uri }}">
                <img
                  alt="{{ app.client_name }}"
                  src="{{ app.client_logo }}"
                  class="img-fluid app-logo"
                />
              </a>
            </li>
          </ng-container>
          <li class="dropdown-item text-center">
            <button type="button" class="btn btn-link btn-signout" (click)="signOut()">
              Sign Out
            </button>
          </li>
        </ul>
      </div>
    </li>
  </ul>
  <div class="divider d-none d-md-block me-md-4"></div>
</div>
