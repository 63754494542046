<div class="card project-card h-100">
  <div
    *ngIf="pageLayout !== pageLayoutType.List"
    class="project-card_banner"
    [style.background-image]="bannerImageUrl"
  ></div>
  <div class="card-body project-card_body">
    <div
      [ngClass]="{
        'project-card_header': true,
        'd-flex flex-wrap align-items-center list-view': pageLayout === pageLayoutType.List
      }"
    >
      <div class="badge_wrapper d-flex justify-content-between align-items-center">
        <div>
          <span
            *ngIf="project.collaborationStatus === 0 || project.collaborationStatus === 1"
            class="text-uppercase text-white font-type-collaboration-badge d-inline-block text-center me-3 cr cr-{{
              project.collaborationStatus
            }}"
            >{{ collaborationLabels[project.collaborationStatus] }}</span
          >
          <span *ngIf="project.superProject" class="project-badge"
            ><i class="icon icon-lightning"></i> Super Project</span
          >
          <div
            *ngIf="!project.superProject && !project.collaborationStatus"
            class="project-badge_empty"
          ></div>
        </div>
        <div class="liked-project">
          <app-project-likes
            [likesCount]="likesCount"
            [userLoggedIn]="userLoggedIn"
            [project]="project"
          ></app-project-likes>
        </div>
      </div>
      <div class="project-date-range">{{ project | projectDateRange }}</div>
    </div>
    <h4
      [ngClass]="{
        'project-card_title h-auto': true,
        'list-view': pageLayout === pageLayoutType.List
      }"
    >
      {{ project.name }}
    </h4>
    <div *ngIf="pageLayout === pageLayoutType.List" class="project-card_description h-auto">
      {{ project.description || displayMessages.DefaultVerbiage }}
    </div>

    <div class="row g-0">
      <div
        [ngClass]="{
          'project-card_details d-flex flex-wrap justify-content-between': true,
          'col-xl-7 col-lg-12  justify-content-xl-start': pageLayout === pageLayoutType.List,
          'col-12': pageLayout === pageLayoutType.Grid
        }"
      >
        <div class="impact-area_wrapper mb-0">
          <div *ngIf="impactTypes.length">
            <p class="impact-area_title">Impact Areas</p>
            <div class="d-inline-flex flex-wrap m-0 pe-5">
              <span *ngFor="let impactType of impactTypes" class="impact-area_icon">
                <i class="icon icon-impact-type-{{ impactType.id }}"></i>
              </span>
              <span class="excess-items">{{ impactTypesExcessItems }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="pageLayout === pageLayoutType.List" class="keywords_wrapper">
          <div *ngIf="project.keywords.length">
            <app-keywords-list
              [displayLimit]="true"
              [keywords]="project.keywords"
            ></app-keywords-list>
          </div>
        </div>
        <div class="project-followers_wrapper">
          <div *ngIf="project?.followers?.length">
            <app-team-avatars
              label="Followers"
              [team]="project.followers"
              [avatarsCount]="2"
              [followersCount]="project.followersCount"
            ></app-team-avatars>
          </div>
        </div>
      </div>
      <div
        class="gap-3"
        [ngClass]="{
          'card-buttons d-flex': true,
          'col-xl-5 col-lg-12 list-view': pageLayout === pageLayoutType.List,
          'col-12': pageLayout === pageLayoutType.Grid
        }"
      >
        <a class="btn btn-outline-primary" [routerLink]="productLink">View Project</a>

        <button *ngIf="!isFollowing" (click)="follow()" class="btn btn-follow-project">
          Follow
        </button>

        <div
          (click)="follow()"
          [class.user-followed-projects]="isAccountPage"
          *ngIf="isFollowing"
          class="btn btn-following"
        >
          <p class="font-type-type-20 text-uppercase mb-0 d-flex justify-content-center">
            <span class="d-flex following">
              <i class="icon icon-check-circle"></i><span>Following</span>
            </span>

            <span class="unfollowing">
              <i class="icon icon-close-alt-red"></i><span>Unfollow</span>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
