import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
// import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// import { InMemoryDataService } from './mocks/in-memory-data.service';

import { Auth } from '@aws-amplify/auth';
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import Hub, { HubClass } from '@aws-amplify/core/lib-esm/Hub';

import {
  AuthGuard,
  AuthInterceptor,
  AuthService,
  UnAuthGuard,
  FlowControlService,
  LicensedGuard,
  MaintenanceGuard,
  LoaderService,
  LoaderInterceptor,
  UnauthorizedInterceptor,
  FileSaverService,
  PrintService,
  ToastService,
  GoogleAnalyticsService,
  ScrollToService,
  MaintenanceService
} from './services';

@NgModule({
  imports: [
    HttpClientModule

    // ! only used for development purposes
    // HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true })
  ],
  providers: [
    GoogleAnalyticsService,
    LoaderService,
    FlowControlService,
    AuthService,
    AuthGuard,
    UnAuthGuard,
    LicensedGuard,
    MaintenanceGuard,
    FileSaverService,
    PrintService,
    ToastService,
    ScrollToService,
    MaintenanceService,

    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true
    },

    { provide: AuthClass, useValue: Auth },
    { provide: HubClass, useValue: Hub }
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error(
        `${parentModule} has already been loaded. Import Core modules in the AppModule only.`
      );
    }
  }
}
