import { IImpactArea, ISdg } from '@app/models';

export const PROJECT_STAGES = {
  ProposedFutureProjects: 'PP',
  CurrentProject: 'CP',
  HistoricalProjects: 'HP'
};

// todo the default color is for further discussion
export const DEFAULT_KEYWORD_COLOR = '#c7c7c7';
export const DEFAULT_BANNER_URL = '/assets/images/default-project-banner.jpg';

export const TAKE_TOP_ITEMS = {
  milestones: 1,
  headlines: 3,
  outputs: 3,
  sdgInHeadlines: 4,
  impactAreas: 2,
  keywords: 2
};

export const AVATARS_TO_DISPLAY = 2;

export const MILESTONES_PROGRESS_UPPER_BOUNDS: { percent: number; color: string }[] = [
  { percent: 20, color: 'red' },
  { percent: 80, color: '#ffbf00' }, // amber
  { percent: 100, color: 'green' }
];

export const SDG_LIST: ISdg[] = [
  { id: '1', description: 'No Poverty' },
  {
    id: '2',
    description: 'Zero Hunger'
  },
  {
    id: '3',
    description: 'Good Health and well-being'
  },
  {
    id: '4',
    description: 'Quality Education'
  },
  {
    id: '5',
    description: 'Gender Equality'
  },
  {
    id: '6',
    description: 'Clean water and sanitation'
  },
  {
    id: '7',
    description: 'Affordable and clean energy'
  },
  {
    id: '8',
    description: 'Decent work and economic growth'
  },
  {
    id: '9',
    description: 'Industry, Innovation and Insfractructure'
  },
  {
    id: '10',
    description: 'Reduced inequalities'
  },
  {
    id: '11',
    description: 'Sustainable cities and communities'
  },
  {
    id: '12',
    description: 'Responsible consumption and production'
  },
  {
    id: '13',
    description: 'Climate action'
  },
  {
    id: '14',
    description: 'Life below water'
  },
  {
    id: '15',
    description: 'Life on land'
  },
  {
    id: '16',
    description: 'Peace, justice and strong institutions'
  },
  {
    id: '17',
    description: 'Partnership for the goals'
  }
];

export const IMPACT_AREAS_LIST: IImpactArea[] = [
  { id: '1', description: 'Academic esteem, teaching and learning' },
  {
    id: '2',
    description: 'Economy, commerce and organisations'
  },
  {
    id: '3',
    description: 'Environment'
  },
  {
    id: '4',
    description: 'Health and welfare'
  },
  {
    id: '5',
    description: 'International development'
  },
  {
    id: '6',
    description: 'Practitioners and professional services'
  },
  {
    id: '7',
    description: 'Public policy, public services and law'
  },
  {
    id: '8',
    description: 'Society, culture and creativity'
  }
];

// Evidences
export const MAX_EVIDENCE_FILE_SIZE_MB = 300; // Megabytes

// Image file
export const MAX_IMAGE_FILE_SIZE_MB = 5; // Megabytes

// Project summary
export const DESCRIPTION_CONTAINER_HEIGHT = 135; // px

// Collaborations
export const COLLABORATION_LABELS = {
  0: 'Pending',
  1: 'Approved',
  2: 'Rejected',
  3: 'Removed'
};

export const COLLABORATION_BUTTON_MESSAGES = {
  collaborateNow: 'Collaborate now',
  requestSent: 'Collaboration request sent',
  sendFeedback: 'Collaboration Accepted',
  disabled: 'Not Open For Collaboration Right Now'
  // sendFeedback: 'Send global feedback'
  // Temporary changed and commented out acording https://vvgroupholdings.atlassian.net/browse/VVIT-24987
};

export const REQUEST_COOLDOWN_INTERVAL = 1000 * 60 * 60 * 24; // 24 hours in milliseconds

export const PAGE_INIT = true;

export const DEFAULT_PAGE_STEP_INCREMENT = 1;

export const FIRST_PAGE_INDEX = 0;

// Lead organizations
export const DEFAULT_ORGANIZATION_TEXT_COLOR = '#505052';
export const ORGANIZATION_TEXT_WHITE_COLOR = '#ffffff';
export const ORGANIZATION_TEXT_WHITE_COLOR_SHORT = '#fff';
export const DEFAULT_LEAD_ORGANIZATION_BOX_COLOR = '#19a0b4';

export const DEFAULT_ORGANIZATION_IMAGE = '/assets/images/ti_symbol_ORGANISATION_PLACE_HOLDER.jpg';
export const DEFAULT_ORGANIZATION_ACTIVE_PROJECTS_COUNT = 1;
