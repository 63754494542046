import { createSelector, MemoizedSelector } from '@ngrx/store';

import { ICurrentUserState } from '@store/state';
import { IProject, ISortingValue } from '@app/models';
import { SortingField, SortingOrder } from '@app/enums';
import { selectCurrentUserState } from './app.selectors';

const sortByName = (order: SortingOrder, projects: IProject[]): IProject[] => {
  if (order === SortingOrder.Asc) {
    return projects.sort((a: IProject, b: IProject) => a.name.localeCompare(b.name));
  }

  if (order === SortingOrder.Desc) {
    return projects.sort((a: IProject, b: IProject) => b.name.localeCompare(a.name));
  }

  return projects;
};

const sortByDate = (order: SortingOrder, projects: IProject[]): IProject[] => {
  if (order === SortingOrder.Asc) {
    return projects.sort((a: IProject, b: IProject) => b.dateAdded.localeCompare(a.dateAdded));
  }

  if (order === SortingOrder.Desc) {
    return projects.sort((a: IProject, b: IProject) => a.dateAdded.localeCompare(b.dateAdded));
  }

  return projects;
};

const sortProjects = (props: ISortingValue, projects: IProject[]): IProject[] => {
  if (props.field === SortingField.Name) {
    return sortByName(props.order, projects);
  }

  if (props.field === SortingField.Date) {
    return sortByDate(props.order, projects);
  }

  return projects;
};

export const selectUserDetails = createSelector(
  selectCurrentUserState,
  (state: ICurrentUserState) => state.userDetails
);

export const selecUpdateProfileError = createSelector(
  selectCurrentUserState,
  (state: ICurrentUserState) => state.error
);

export const selectFollowedProjects: MemoizedSelector<object, IProject[]> = createSelector(
  selectCurrentUserState,
  (state: ICurrentUserState, props?: ISortingValue): IProject[] => {
    if (props) {
      return sortProjects(props, [...state.followedProjects]);
    }

    return state.followedProjects;
  }
);

export const selectCollaborationProjects: MemoizedSelector<object, IProject[]> = createSelector(
  selectCurrentUserState,
  (state: ICurrentUserState, props?: ISortingValue): IProject[] => {
    let filterdCollaborationProjects = [...state.collaborationProjects];
    filterdCollaborationProjects = filterdCollaborationProjects.filter(
      (project) => project.collaborationStatus === 0 || project.collaborationStatus === 1
    );

    if (props) {
      return sortProjects(props, filterdCollaborationProjects);
    }

    return filterdCollaborationProjects;
  }
);

export const selectLikedProjects: MemoizedSelector<object, IProject[]> = createSelector(
  selectCurrentUserState,
  (state: ICurrentUserState, props?: ISortingValue): IProject[] => {
    return state.likedProjects;
  }
);

export const selectUserProjects: MemoizedSelector<object, IProject[]> = createSelector(
  selectCurrentUserState,
  (state: ICurrentUserState, props?: ISortingValue): IProject[] => {
    if (props) {
      return sortProjects(props, [...state.userProjects]);
    }

    return state.userProjects;
  }
);

export const selectIsLoadingProjects = createSelector(
  selectCurrentUserState,
  (state: ICurrentUserState) => {
    return {
      isLoading: state.isLoading,
      currentLoadingId: state.currentLoadingId
    };
  }
);
