import { Pipe, PipeTransform } from '@angular/core';
import { EVIDENCE_FILE_UPLOAD_EXTENSIONS, EVIDENCE_TYPE_CODES } from '@app/constants';
import { EvidenceType } from '@app/enums';
import { IEvidence } from '@app/models';

@Pipe({
  name: 'mapEvidenceTypeCode'
})
export class MapEvidenceTypeCodePipe implements PipeTransform {
  transform(value: IEvidence, ...args: unknown[]): string {
    let code = EVIDENCE_TYPE_CODES.other;
    if (value.type === EvidenceType.FreeText) {
      code = EVIDENCE_TYPE_CODES.freeText;
    } else if (value.type === EvidenceType.RcukData) {
      code = EVIDENCE_TYPE_CODES.rcukData;
    } else if (value.type === EvidenceType.WebClipping) {
      code = EVIDENCE_TYPE_CODES.webClipping;
    } else if (value.type === EvidenceType.Tweet) {
      code = EVIDENCE_TYPE_CODES.twitter;
    } else if (value.type === EvidenceType.FileUpload) {
      code = value.fileInfo?.extension || EVIDENCE_TYPE_CODES.fileUpload;
    } else if (value.type === EvidenceType.SymplecticElements) {
      code = EVIDENCE_TYPE_CODES.symplecticElements;
    } else if (value.type === EvidenceType.Email) {
      code = EVIDENCE_TYPE_CODES.email;
    }

    return code;
  }
}

@Pipe({
  name: 'mapEvidenceTypeCodeColor'
})
export class MapEvidenceTypeCodeColorPipe implements PipeTransform {
  transform(value: IEvidence, ...args: unknown[]): string {
    let color = 'grey';
    if (value.type === EvidenceType.FreeText) {
      color = 'orange';
    } else if (value.type === EvidenceType.RcukData) {
      color = 'blue';
    } else if (value.type === EvidenceType.WebClipping) {
      color = 'light-purple';
    } else if (value.type === EvidenceType.Tweet) {
      color = 'dark-blue';
    } else if (value.type === EvidenceType.FileUpload) {
      const extension = value.fileInfo?.extension;
      color = 'purple';

      if (extension === 'pdf') {
        color = 'red';
      } else if (extension === 'docx') {
        color = 'dark-green';
      } else if (extension === 'audio') {
        color = 'pink';
      } else if (extension === 'video') {
        color = 'yellow';
      } else if (extension === 'excel') {
        color = 'dark-grey';
      } else if (extension && EVIDENCE_FILE_UPLOAD_EXTENSIONS.image.includes(extension)) {
        color = 'lime-green';
      }
    } else if (value.type === EvidenceType.SymplecticElements) {
      color = 'dark-red';
    } else if (value.type === EvidenceType.Email) {
      color = 'light-green';
    }

    return color;
  }
}
