import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '@app/services';
import { LOGO_URL } from '@app/constants';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss']
})
export class HomeHeaderComponent implements OnInit {
  isCollapsed = true;
  isLoggedIn$: Observable<boolean>;

  get logoSrc(): string {
    return LOGO_URL.DEFAULT;
  }

  constructor(private authService: AuthService) {
    this.isLoggedIn$ = this.authService.isLoggedIn$;
  }

  ngOnInit(): void {}
}
