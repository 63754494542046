import { registerAction } from '@app/utils';
import { createAction } from '@ngrx/store';

class SpinnerActions {
  static readonly showSpinner = registerAction('[Spinner] Show Spinner');
  static readonly hideSpinner = registerAction('[Spinner] Hide Spinner');
}

export const showSpinner = createAction(SpinnerActions.showSpinner);
export const hideSpinner = createAction(SpinnerActions.hideSpinner);
