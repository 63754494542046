import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { buildInitialBreadcrumbs, refineBreadcrumbs } from '@store/actions';
import { selectBreadcrumbList } from '@store/selectors';
import { IBreadcrumb } from '@app/models';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  breadcrumb!: IBreadcrumb[];
  breadcrumbList$!: Observable<IBreadcrumb[]>;

  private stop$ = new Subject<void>();

  constructor(private router: Router, private store: Store) {
    this.store.dispatch(buildInitialBreadcrumbs());
    this.breadcrumbList$ = this.store.pipe(select(selectBreadcrumbList));
  }

  ngOnInit(): void {
    this.store.dispatch(refineBreadcrumbs());
    this.breadcrumbList$
      .pipe(takeUntil(this.stop$))
      .subscribe((value) => (this.breadcrumb = value));
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }

  redirect(selectedBreadcrumb: IBreadcrumb, index: number): void {
    // Clear all the items after the selected breadcrumb url
    this.breadcrumb = this.breadcrumb.slice(0, index);

    // commented out because of the search updates, please don't remove for now
    // this.router.navigate([selectedBreadcrumb.url], { queryParams: selectedBreadcrumb.queryParams });
    this.router.navigate([selectedBreadcrumb.url]);
  }
}
