import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EMAIL_PATTERN, POPULAR_DOMAINS } from '@app/constants';

export function organizationEmailValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value && control.valid) {
    const emailDomain = control.value.split('@')[1];
    const isBlacklistDomain =
      emailDomain &&
      POPULAR_DOMAINS.some(
        (popularDomain) => popularDomain.toLowerCase() === emailDomain.toLowerCase()
      );

    if (isBlacklistDomain) {
      return { invalidOrganizationEmail: true };
    }
    return null;
  }
  return null;
}

export function gdprAgreementCheckboxValidator(
  formGroup: AbstractControl
): ValidationErrors | null {
  if (!formGroup.get('gdprAgreement')?.value) {
    return { invalidGdprAgreementControl: true };
  }
  return null;
}

export const EMAIL_VALIDATORS: ValidatorFn[] = [
  Validators.maxLength(320),
  Validators.pattern(EMAIL_PATTERN)
];
