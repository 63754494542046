export const BREADCRUMB_PATH_LABELS = {
  HOME: 'Home',
  PROJECTS: 'Projects',
  PROJECT: 'Project',
  PROFILE: 'Settings',
  PRIVACY: 'Privacy Policy',
  TERMS: 'Terms and Conditions',
  EULA: 'End User License Agreement'
};

export const BREADCRUMB_PATH_IDS = {
  HOME: 'home-page',
  PROJECTS: 'search-projects',
  PROJECT: 'view-project',
  PROFILE: 'my-profile',
  PRIVACY: 'privacy-policy',
  TERMS: 'terms-and-conditions',
  EULA: 'eula'
};

export const BREADCRUMB_MAX_LABEL_LENGTH = 30; // chars length
