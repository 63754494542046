import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IToast } from '@app/models';
import { ToastService } from '@app/services';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent implements OnInit, OnDestroy, AfterContentInit {
  value: IToast[] = [];
  routeSubscription!: Subscription;

  private stop$ = new Subject<void>();

  constructor(
    private toastService: ToastService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.routeSubscription = this.router.events.pipe(takeUntil(this.stop$)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.clearToast();
      }
    });
  }

  ngAfterContentInit(): void {
    this.toastService.toastObserver.pipe(takeUntil(this.stop$)).subscribe((toast: IToast[]) => {
      if (toast && toast.length) {
        this.value = [...this.value, ...toast];

        toast.forEach((element) => {
          setTimeout(() => {
            const index = this.value.indexOf(element);

            this.value.splice(index, 1);

            this.cd.markForCheck();
          }, 3000);
        });

        this.cd.markForCheck();
      }
    });
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }

  clearToast(): void {
    this.value = [];
    this.cd.markForCheck();
  }
}
