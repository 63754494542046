export enum EvidenceType {
  Tweet = 'Tweet',
  FreeText = 'FreeText',
  RcukData = 'RcukData',
  FileUpload = 'FileUpload',
  WebClipping = 'WebClipping',
  SymplecticElements = 'SymplecticElements',
  Email = 'Email'
}

export enum CollaborationStatus {
  RequestPending = 0,
  RequestApproved = 1,
  RequestRejected = 2,
  RequestRemoved = 3
}

export enum EvaluationStatus {
  Pending = 0,
  Evaluated = 1
}

export enum CollaboratorRole {
  Owner = 0,
  Guest = 1,
  Collaborator = 2
}

export enum CollaboratorType {
  Person = 0,
  Group = 1
}

export enum ProjectSortBy {
  Name = 'name',
  DateAdded = 'dateAdded'
}
