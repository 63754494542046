import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  APP_ROUTES,
  COLLABORATION_LABELS,
  DEFAULT_BANNER_URL,
  DISPLAY_MESSAGES,
  TAKE_TOP_ITEMS
} from '@app/constants';
import { PageLayout } from '@app/enums';
import { IImpactType, IProject } from '@app/models';
import { AuthService } from '@app/services';
import { toggleFollowSearchResultProject } from '@modules/search-result/store/actions';
import { selectFollowedProjects } from '@store/selectors/user-details.selectors';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss']
})
export class ProjectCardComponent implements OnInit, OnDestroy {
  readonly collaborationLabels = COLLABORATION_LABELS;
  readonly displayMessages = DISPLAY_MESSAGES;
  readonly pageLayoutType = PageLayout;
  readonly followedProjects$: Observable<IProject[]> = this.store.select(selectFollowedProjects);

  private _impactTypesExcessItems!: number;
  private stop$ = new Subject<void>();
  private followedProjects!: IProject[];

  subscription!: Subscription;
  userLoggedIn!: boolean;

  likesCount!: number;

  @Input() project!: IProject;
  @Input() pageLayout?: string = this.pageLayoutType.Grid;

  get productLink(): string {
    return `/${APP_ROUTES.PROJECT}/${this.project.region}/${this.project.id}`;
  }

  get bannerImageUrl(): string {
    return this.project.bannerUrl
      ? `url("${this.project.bannerUrl}")`
      : `url("${DEFAULT_BANNER_URL}")`;
  }

  get impactTypes(): IImpactType[] {
    const totalItems = this.project.impactTypes.length;
    const impactTypes = this.project.impactTypes.slice(0, TAKE_TOP_ITEMS.impactAreas);
    this._impactTypesExcessItems = totalItems - impactTypes.length;

    return impactTypes;
  }

  get impactTypesExcessItems(): string {
    return this._impactTypesExcessItems ? `+${this._impactTypesExcessItems}` : '';
  }

  get isAccountPage(): boolean {
    return this.router.url === ['/', APP_ROUTES.MY_PROJECTS].join('');
  }

  get isFollowing(): boolean {
    return this.followedProjects.map((p) => Number(p.id)).includes(Number(this.project.id));
  }

  constructor(private router: Router, private authService: AuthService, private store: Store) {}

  ngOnInit(): void {
    this.authService.isLoggedIn$
      .pipe(takeUntil(this.stop$))
      .subscribe((value) => (this.userLoggedIn = value));

    this.followedProjects$.subscribe((projects) => {
      this.followedProjects = projects;
    });

    this.likesCount = this.project.likesCount;
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }

  follow(): void {
    if (this.userLoggedIn) {
      this.store.dispatch(
        toggleFollowSearchResultProject({
          project: this.project,
          region: this.project.region,
          following: !this.isFollowing
        })
      );
    } else {
      this.authService.signIn();
    }
  }
}
