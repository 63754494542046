import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Action } from '@ngrx/store';

import { environment } from '@env';
import { IAccount, IProject } from '@app/models';

import * as UserDetailsActions from '../actions';
import { AuthService } from '@app/services';
import { UserProjectListType } from '@app/enums';

@Injectable()
export class UserDetailsEffects {
  private loadUserDetailsUrl = `${environment.apiUrl}/load-user-details`;
  private userProjectsUrl = `${environment.apiUrl}/user-projects`;

  constructor(
    private authService: AuthService,
    private actions$: Actions,
    private http: HttpClient
  ) {}

  loadUserDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDetailsActions.loadUserDetails),
      switchMap((action) =>
        this.http.post<IAccount>(this.loadUserDetailsUrl, {}).pipe(
          map((data) =>
            UserDetailsActions.loadUserDetailsSuccess({
              details: data
            })
          ),
          catchError((error) => of(UserDetailsActions.loadUserDetailsError({ error })))
        )
      )
    )
  );

  loadFollowedProjects$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDetailsActions.loadFollowedProjects),
      withLatestFrom(this.authService.authState$),
      switchMap(([action, userState]) =>
        this.http
          .post<IProject[]>(this.userProjectsUrl, {
            listType: UserProjectListType.followed
          })
          .pipe(
            map((records: IProject[]) =>
              UserDetailsActions.loadFollowedProjectsSuccess({ projects: records })
            ),
            catchError((error) => of(UserDetailsActions.loadFollowedProjectsError({ error })))
          )
      )
    )
  );

  loadCollaborationProjects$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDetailsActions.loadCollaborationProjects),
      withLatestFrom(this.authService.authState$),
      switchMap(([action, userState]) =>
        this.http
          .post<IProject[]>(this.userProjectsUrl, {
            listType: UserProjectListType.collaboration
          })
          .pipe(
            map((records) =>
              UserDetailsActions.loadCollaborationProjectsSuccess({ projects: records })
            ),
            catchError((error) => of(UserDetailsActions.loadCollaborationProjectsError({ error })))
          )
      )
    )
  );

  loadLikedProjects$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDetailsActions.loadLikedProjects),
      withLatestFrom(this.authService.authState$),
      switchMap(([action, userState]) =>
        this.http
          .post<IProject[]>(this.userProjectsUrl, {
            listType: UserProjectListType.liked
          })
          .pipe(
            map((records) => UserDetailsActions.loadLikedProjectsSuccess({ projects: records })),
            catchError((error) => of(UserDetailsActions.loadLikedProjectsError({ error })))
          )
      )
    )
  );

  loadUserProjects$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserDetailsActions.loadUserProjects),
      withLatestFrom(this.authService.authState$),
      switchMap(([action, userState]) =>
        this.http
          .post<{ records: IProject[] }>(this.userProjectsUrl, {
            username: userState.username,
            listType: UserProjectListType.owned
          })
          .pipe(
            map(({ records }) => UserDetailsActions.loadUserProjectsSuccess({ projects: records })),
            catchError((error) => of(UserDetailsActions.loadUserProjectsError({ error })))
          )
      )
    )
  );
}
