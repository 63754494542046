import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { hideSpinner, showSpinner } from '@store/actions';

@Injectable()
export class LoaderService {
  constructor(private store: Store) {}

  show(): void {
    this.store.dispatch(showSpinner());
  }

  hide(): void {
    this.store.dispatch(hideSpinner());
  }
}
