export const PHONE_NUMBER_PATTERN = '^([0-9]{5,20})$';
export const WEBSITE_PATTERN = '^(https://)?([\\da-z\\.-]+)\\.([a-z\\.]{2,6})([\\.-]*)[^\\.]+$';
export const EMAIL_PATTERN = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
export const LINKEDIN_PATTERN = 'https?://(www.linkedin.com/in/)[-a-zA-Z0-9@:%._+~#=]{2,256}/?';
export const TWITTER_PATTERN = 'https?://(www.twitter.com/)[-a-zA-Z0-9@:%._+~#=]{2,256}/?';
export const RESEARCHGATE_PATTERN =
  'https?://(www.researchgate.net/profile/)[-a-zA-Z0-9@:%._+~#=]{2,256}/?';
export const ACADEMIA_PATTERN = 'https?://(team.academia.edu/)[-a-zA-Z0-9@:%._+~#=]{2,256}/?';
export const MENDELEY_PATTERN =
  'https?://(www.mendeley.com/profiles/)[-a-zA-Z0-9@:%._+~#=]{2,256}/?';
