import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SortingField, SortingOrder } from '@app/enums';
import { IProject, ISortingValue } from '@app/models';
import { ISearchResultState } from '../state';

const sortByName = (order: SortingOrder, projects: IProject[]) => {
  if (order === SortingOrder.Asc) {
    return projects.sort((a: IProject, b: IProject) => a.name.localeCompare(b.name));
  }

  if (order === SortingOrder.Desc) {
    return projects.sort((a: IProject, b: IProject) => b.name.localeCompare(a.name));
  }

  return projects;
};

const sortByDate = (order: SortingOrder, projects: IProject[]) => {
  if (order === SortingOrder.Asc) {
    return projects.sort((a: IProject, b: IProject) => b.dateAdded.localeCompare(a.dateAdded));
  }

  if (order === SortingOrder.Desc) {
    return projects.sort((a: IProject, b: IProject) => a.dateAdded.localeCompare(b.dateAdded));
  }

  return projects;
};

const sortProjects = (props: ISortingValue, projects: IProject[]) => {
  if (props.field === SortingField.Name) {
    return sortByName(props.order, projects);
  }

  if (props.field === SortingField.Date) {
    return sortByDate(props.order, projects);
  }

  return projects;
};

export const selectSearchResultState = createFeatureSelector<ISearchResultState>('searchResult');

export const selectProjectSearchResult = createSelector(
  selectSearchResultState,
  (state: ISearchResultState, props: ISortingValue) =>
    props ? sortProjects(props, [...state.projects]) : state.projects
);

export const selectProjectSearchFilters = createSelector(
  selectSearchResultState,
  (state: ISearchResultState) => state.filters
);

export const selectPagingParamsResult = createSelector(
  selectSearchResultState,
  (state: ISearchResultState) => state.searchParams.pagingParams
);

export const selectTotalRecords = createSelector(
  selectSearchResultState,
  (state: ISearchResultState) => state.totalRecords
);

export const selectSearchParams = createSelector(
  selectSearchResultState,
  (state: ISearchResultState) => state.searchParams
);

export const selectCurrentPage = createSelector(
  selectSearchResultState,
  (state: ISearchResultState) => state.currentPage
);

export const selectIsLoadingSearchResults = createSelector(
  selectSearchResultState,
  (state: ISearchResultState) => state.isLoading
);

export const selectLikesCount = createSelector(
  selectSearchResultState,
  (state: ISearchResultState) => {
    return {
      likesCount: state.likesCount,
      currentLoadingId: state.currentLoadingId
    };
  }
);

export const selectIsLoadingLikedProject = createSelector(
  selectSearchResultState,
  (state: ISearchResultState) => {
    return {
      isLoadingLikedProject: state.isLoadingLikedProject,
      currentLoadingId: state.currentLoadingId
    };
  }
);
