<nav aria-label="breadcrumb">
  <ol class="breadcrumb mb-0">
    <li
      *ngFor="let breadcrumb of breadcrumbList$ | async; last as isLast; let i = index"
      class="breadcrumb-item pb-3 pb-sm-0 font-type-type-27"
      [ngClass]="{ active: isLast }"
    >
      <a *ngIf="!isLast; else lastRoute" href="#" [routerLink]="" (click)="redirect(breadcrumb, i)">
        {{ breadcrumb.label }}
      </a>
      <ng-template #lastRoute>{{ breadcrumb.label }}</ng-template>
    </li>
  </ol>
</nav>
