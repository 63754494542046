import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import Amplify from '@aws-amplify/core';
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import { HubClass } from '@aws-amplify/core/lib-esm/Hub';

import { environment } from '@env';
import { APP_ROUTES } from '@app/constants';
import {
  loadUserDetails,
  loadFollowedProjects,
  loadCollaborationProjects,
  loadLikedProjects
} from '@store/actions/user-details.actions';

import { AUTH_EVENT, INITIAL_AUTH_STATE } from './auth.const';
import { IAuthState, ICognitoUser, IUser } from './auth.model';
import { IAppClient } from '@app/models';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class AuthService implements OnDestroy {
  error$ = new Subject<string>();
  success$ = new Subject<string>();

  private readonly authState = new BehaviorSubject<IAuthState>(INITIAL_AUTH_STATE);

  private returnLink: string | undefined | null;

  get accessIdToken$(): Observable<string> {
    return from(this.amplifyAuth.currentSession()).pipe(
      switchMap((session) => of(session.getIdToken().getJwtToken())),
      catchError(() => of(''))
    );
  }

  get authState$(): Observable<IAuthState> {
    return this.authState.asObservable();
  }

  get isLoggedIn$(): Observable<boolean> {
    return this.authState$.pipe(map((state) => state.isLoggedIn));
  }

  constructor(
    private router: Router,
    private store: Store,
    private http: HttpClient,
    private hub: HubClass,
    private amplifyAuth: AuthClass
  ) {
    Amplify.configure(environment.awsCognito.amplify);

    // Get the user on creation of this service
    this.amplifyAuth.currentAuthenticatedUser().then(
      (user: ICognitoUser) => this.setUser(user),
      () => this.authState.next(INITIAL_AUTH_STATE)
    );

    // Use Hub channel 'auth' to get notified on changes
    this.hub.listen('auth', ({ payload: { event, data, message } }) => {
      if (event === AUTH_EVENT.SIGN_IN) {
        // On 'signIn' event, the data is a CognitoUser object
        this.setUser(data);

        // redirect to returnUrl route if user is authenticated
        const returnUrl = sessionStorage.getItem('returnUrl');

        if (returnUrl) {
          sessionStorage.removeItem('returnUrl');
          this.router.navigateByUrl(returnUrl);
        }
      }

      if (event === AUTH_EVENT.SIGN_OUT) {
        this.authState.next(INITIAL_AUTH_STATE);
      }
    });

    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
  }

  signIn(): void {
    sessionStorage.setItem('returnUrl', this.router.url);
    this.amplifyAuth.federatedSignIn();
  }

  signOut(): void {
    this.amplifyAuth.signOut().then(() => {
      this.router.navigate([APP_ROUTES.HOME]);
      localStorage.setItem('login-event', 'login' + Math.random());
    });
  }

  async loadApps(): Promise<IAppClient[]> {
    let regionId: string | null = null;
    this.authState$.subscribe((x) => (regionId = x.region));

    const auth = environment.awsCognito.amplify.Auth;
    const clientId = auth.userPoolWebClientId;
    const domain = auth.oauth.domain;

    return new Promise<IAppClient[]>((resolve, reject) => {
      this.http
        .get<IAppClient[]>(`https://${domain}/clients-redirection`, {
          params: {
            client_id: clientId,
            region: `${regionId}`
          }
        })
        .subscribe({
          next: (result) => {
            resolve(result);
          },
          error: (error) => {
            console.error('Could not get clients. Server response: ' + JSON.stringify(error));
            reject();
          }
        });
    });
  }

  private setUser(user: ICognitoUser): void {
    if (!user) {
      return;
    }

    if (user.attributes) {
      const {
        attributes: { sub: id, email, 'custom:region': region },
        username
      } = user;

      this.authState.next({ isLoggedIn: true, id, username, email, region });
      this.store.dispatch(loadUserDetails({ username }));
      this.store.dispatch(loadFollowedProjects());
      this.store.dispatch(loadCollaborationProjects());
      this.store.dispatch(loadLikedProjects());
    }
  }

  private storageEventListener(event: StorageEvent): void {
    if (event.storageArea === localStorage) {
      if (event.key === 'logout-event') {
        this.authState.next(INITIAL_AUTH_STATE);
      }
      if (event.key === 'login-event') {
        location.reload();
      }
    }
  }
}
