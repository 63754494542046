import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent {
  @Input() inNavbar = false;
  @Input() menuClass = '';

  constructor(public translateService: TranslateService) {}

  getAvailableLanguages(): string[] {
    return this.translateService.getLangs();
  }
}
