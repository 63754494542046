import { ToolbarSettingsModel } from '@syncfusion/ej2-angular-richtexteditor';

export const RTE_TOOLBAR: ToolbarSettingsModel = {
  items: [
    'Bold',
    'Italic',
    'StrikeThrough',
    'SourceCode',
    'CreateLink',
    'OrderedList',
    'UnorderedList',
    'Alignments'
  ],
  enableFloating: false
};
