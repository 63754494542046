import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { APP_ROUTES } from '@app/constants';
import { environment } from '@env';

@Injectable()
export class LicensedGuard implements CanLoad {
  constructor(private router: Router) {}

  canLoad(): boolean {
    if (environment.production) {
      const userAccessCode = localStorage.getItem('userAccessCode');

      if (userAccessCode === environment.appAccessCode) {
        return true;
      }
      this.router.navigate([APP_ROUTES.WELCOME]);

      return false;
    } else {
      return true;
    }
  }
}
