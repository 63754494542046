import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { environment } from '@env';

@Component({
  selector: 'app-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecaptchaComponent),
      multi: true
    }
  ]
})
export class RecaptchaComponent implements OnInit, OnDestroy, ControlValueAccessor {
  recaptchaForm: FormGroup;
  recaptchaControl: FormControl;

  recaptchaSiteKey = environment.recaptchaSiteKey;

  private stop$ = new Subject<void>();

  constructor(private fb: FormBuilder) {
    this.recaptchaForm = this.fb.group({
      recaptcha: (this.recaptchaControl = this.fb.control(null, Validators.required))
    });
  }

  ngOnInit(): void {
    this.recaptchaForm.valueChanges
      .pipe(takeUntil(this.stop$))
      .subscribe((value) => this.onChangeCallback(value));
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }

  resolved(captchaResponse: string): void {
    if (!captchaResponse) {
      this.onChangeCallback(captchaResponse);
    }
  }

  onError(errorDetails: RecaptchaErrorParameters): void {}

  writeValue(): void {}

  registerOnChange(fn: () => void): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {}

  private onChangeCallback: (_: string) => void = () => {};
}
