<div
  class="collapse navbar-collapse justify-content-end bg-white navbar-content w-100 top-0"
  id="navbarCollapse"
>
  <div class="col-12 d-md-none d-flex mt-lg-0 mt-4 px-4 px-md-0 py-4 border-bottom">
    <div class="col-10 text-primary font-type-nav-link">Menu</div>
    <div class="col text-end">
      <button
        #closeButton
        class="navbar-toggler border-0"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
      >
        <i class="icon icon-close"></i>
      </button>
    </div>
  </div>
  <ul class="navbar-nav ml-auto p-md-0 p-4">
    <li class="nav-item my-2" *ngFor="let navItem of navItems" routerLinkActive="active">
      <a
        *ngIf="navItem.routerLink"
        class="nav-link"
        [routerLink]="navItem.routerLink"
        [target]="navItem.target || ''"
        >{{ navItem.label }}</a
      >
      <a
        *ngIf="navItem.href"
        class="nav-link"
        [href]="navItem.href"
        [target]="navItem.target || ''"
        >{{ navItem.label }}</a
      >
    </li>
  </ul>
  <div class="product-list-no-mobile">
    <app-private-top-navbar *ngIf="isLoggedIn$ | async"></app-private-top-navbar>
  </div>
  <app-nav-account
    [isLoggedIn]="isLoggedIn$ | async"
    [userDetails]="userDetails$ | async"
    (userSignOut)="signOut()"
    (toggleDropDown)="closeButton.click()"
  >
  </app-nav-account>
</div>
