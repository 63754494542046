import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IToast } from '@app/models';

@Injectable()
export class ToastService {
  private toastSource = new Subject<IToast[]>();

  constructor() {}

  toastObserver = this.toastSource.asObservable();

  addAll(toast: IToast[]): void {
    if (toast && toast.length) {
      this.toastSource.next(toast);
    }
  }
}
