import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'withDefaultValue'
})
export class WithDefaultValuePipe implements PipeTransform {
  defaultFallback = '–';
  transform(value: string | null, defaultValue: string = this.defaultFallback): string {
    if (!!value) {
      return value;
    } else {
      return defaultValue;
    }
  }
}
