import { Injectable } from '@angular/core';
import printJS from 'print-js';

@Injectable()
export class PrintService {
  constructor() {}

  printHtml(sourceHtmlElementId: string): void {
    printJS(sourceHtmlElementId, 'html');
  }

  printPdf(url: string): void {
    printJS({ printable: url, showModal: true });
  }
}
