// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from '@app/models';

export const environment: IEnvironment = {
  production: false,
  env: 'dev',
  awsCognito: {
    amplify: {
      Auth: {
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_iAbKQJy7I',
        userPoolWebClientId: '7m2i5phd4blcnasokr3ivjiu6p',
        oauth: {
          domain: 'dev-myvvid.com',
          scope: ['email', 'profile', 'openid'],
          redirectSignIn: 'https://dev-trackimpact.org',
          redirectSignOut: 'https://dev-trackimpact.org',
          responseType: 'code'
        }
      }
    },
    signUp: {
      url: 'https://dev-myvvid.com/create-account',
      clientId: '7m2i5phd4blcnasokr3ivjiu6p'
    },
    userConfirmation: {
      email: '',
      password: ''
    },
    forgotPasswordUsername: ''
  },
  apiUrl: '/api',
  appAccessCode: 'RzXiOa45N',
  recaptchaSiteKey: '6Lc13QUgAAAAAF9Y6tncmhCl6mP47v-422NtjOdA',
  googleAnalytics: {
    enabled: true,
    gtag: 'G-Y8C7ZWLFEK',
    src: 'https://www.googletagmanager.com/gtag/js?id=G-Y8C7ZWLFEK',
    script: `window.dataLayer = window.dataLayer || [];function gtag() {dataLayer.push(arguments)}gtag('js', new Date());gtag('config', 'G-Y8C7ZWLFEK');`
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
