import { IProject } from '@app/models';
import { ActionReducer, createReducer, on } from '@ngrx/store';
import { ICurrentUserState } from '@store/state';

import * as UserDetailsActions from '../actions/user-details.actions';

export const initialState: ICurrentUserState = {
  userDetails: null,
  followedProjects: [],
  collaborationProjects: [],
  likedProjects: [],
  userProjects: [],
  error: '',
  isLoading: false,
  currentLoadingId: 0
};

export const currentUserReducer: ActionReducer<ICurrentUserState> = createReducer(
  initialState,
  on(UserDetailsActions.loadUserDetailsSuccess, (state, props) => ({
    ...state,
    userDetails: props.details
  })),
  on(UserDetailsActions.updateUserProfileError, (state, props) => ({
    ...state,
    error: props.error
  })),
  on(UserDetailsActions.clearUserDetails, (state) => ({
    ...state,
    userDetails: null
  })),
  on(UserDetailsActions.toggleProjectFollow, (state, props) => ({
    ...state,
    followedProjects: toggleFollowing(state, props.project, props.isFollow)
  })),
  on(UserDetailsActions.loadFollowedProjectsSuccess, (state, props) => ({
    ...state,
    followedProjects: props.projects
  })),
  on(UserDetailsActions.loadCollaborationProjectsSuccess, (state, action) => ({
    ...state,
    collaborationProjects: action.projects
  })),
  on(UserDetailsActions.loadLikedProjects, (state) => ({
    ...state,
    isLoading: true
  })),
  on(UserDetailsActions.loadLikedProjectsSuccess, (state, props) => ({
    ...state,
    likedProjects: props.projects,
    isLoading: false
  })),
  on(UserDetailsActions.loadUserProjectsSuccess, (state, action) => ({
    ...state,
    userProjects: action.projects,
    isLoading: false
  })),
  on(UserDetailsActions.toggleLikedProject, (state, props) => ({
    ...state,
    currentLoadingId: props.projectId
  }))
);

function toggleFollowing(
  state: ICurrentUserState,
  project: IProject,
  isFollow: boolean
): IProject[] {
  const followedProjects = state.followedProjects.slice() || [];

  if (!isFollow) {
    return followedProjects.filter((p) => p.id !== +project.id);
  } else {
    followedProjects.push(project);
    return followedProjects;
  }
}
