import { createAction, props } from '@ngrx/store';

import { registerAction } from '@app/utils';
import { IBreadcrumb } from '@app/models';

class BreadcrumbActions {
  static readonly buildInitialBreadcrumbs = registerAction(
    '[Breadcrumb] Build Initial Breadcrumb List'
  );
  static readonly refineBreadcrumbs = registerAction('[Breadcrumb] Refine Breadcrumb List');
  static readonly updateBreadcrumbList = registerAction('[Breadcrumb] Update Breadcrumb List');
  static readonly updateBreadcrumbItem = registerAction('[Breadcrumb] Update Breadcrumb Item');
}

export const buildInitialBreadcrumbs = createAction(BreadcrumbActions.buildInitialBreadcrumbs);
export const refineBreadcrumbs = createAction(BreadcrumbActions.refineBreadcrumbs);

export const updateBreadcrumbList = createAction(
  BreadcrumbActions.updateBreadcrumbList,
  props<{ breadcrumbList: IBreadcrumb[] }>()
);

export const updateBreadcrumbItemLabel = createAction(
  BreadcrumbActions.updateBreadcrumbItem,
  props<{ breadcrumbId: string; label: string }>()
);
