import { NgModule } from '@angular/core';

import { environment } from '@env';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { BreadcrumbsEffects, UserDetailsEffects } from './effects';
import { appReducers } from './state';

// tslint:disable-next-line: no-any
export const metaReducers: MetaReducer<any>[] = environment.production ? [] : []; // [storeFreeze];

@NgModule({
  imports: [
    StoreModule.forRoot(appReducers, { metaReducers }),
    EffectsModule.forRoot([UserDetailsEffects, BreadcrumbsEffects])
  ],
  providers: [],
  exports: [StoreModule, EffectsModule]
})
export class AppStoreModule {}
