import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectUserDetails } from '@store/selectors';
import { clearUserDetails } from '@store/actions';

import { IAccount, INavItem } from '@app/models';
import { AuthService } from '@app/services';
import { APP_ROUTES, EXTERNAL_URL } from '@app/constants';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @Input() isCollapsed = true;
  readonly appRoutes = APP_ROUTES;
  readonly externalUrls = EXTERNAL_URL;

  navItems: INavItem[];

  isLoggedIn$: Observable<boolean>;
  userDetails$: Observable<IAccount | null>;

  constructor(private authService: AuthService, private store: Store) {
    this.isLoggedIn$ = this.authService.isLoggedIn$;
    this.userDetails$ = this.store.pipe(select(selectUserDetails));

    this.navItems = [
      {
        label: 'Projects',
        routerLink: `/${APP_ROUTES.PROJECT_SEARCH}`,
        target: '_self'
      },
      { label: 'Start Tracking', href: EXTERNAL_URL.VV_OFFICIAL, target: '_blank' }
    ];
  }

  ngOnInit(): void {}

  signOut(): void {
    this.authService.signOut();
    this.store.dispatch(clearUserDetails());
  }
}
