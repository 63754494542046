import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * @example
 * ({ equal: boolean, maxLength: boolean, minLength: boolean })
 */
export enum RichTextLengthComparator {
  EQUAL = 'equal',
  MAX = 'max',
  MIN = 'min'
}

/**
 * @param validator: RichTextLengthComparator
 * @param value: Value used by the comparing operator
 */
export function richTextValidator(validator: RichTextLengthComparator, value: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const { textContent } = new DOMParser().parseFromString(control.value || '', 'text/html').body;

    if (textContent) {
      if (validator === RichTextLengthComparator.EQUAL) {
        if (textContent.length !== value) {
          control.setErrors({ equal: true });
        } else {
          control.setErrors({});
        }
      } else if (validator === RichTextLengthComparator.MAX) {
        if (textContent.length > value) {
          control.setErrors({ maxLength: true });
        } else {
          control.setErrors({});
        }
      } else if (validator === RichTextLengthComparator.MIN) {
        if (textContent.length < value) {
          control.setErrors({ minLength: true });
        } else {
          control.setErrors({});
        }
      }
    }

    return control.errors;
  };
}
