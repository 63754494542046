import { Inject, NgModule } from '@angular/core';
import { CommonModule, KeyValuePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { QRCodeModule } from 'angularx-qrcode';

import {
  ProgressGaugeComponent,
  ProjectCardComponent,
  TeamAvatarsComponent,
  OrganizationInfoRowComponent,
  SpinnerComponent,
  SdgListComponent,
  KeywordsListComponent,
  DefaultAvatarComponent,
  PasswordStrengthMeterComponent,
  RecaptchaComponent,
  ToastComponent,
  QrCodeComponent,
  ProjectLikesComponent
} from './components';

import { SyncfusionModule } from './syncfusion';
import { PaginatorModule } from '@primeng/paginator';

import {
  ConvertToBytesPipe,
  DateRangePipe,
  MapEvidenceBannerSrcPipe,
  MapEvidenceTypeCodePipe,
  MapEvidenceTypeCodeColorPipe,
  WithDefaultValuePipe,
  ProjectDateRangePipe,
  CustomNumberPipe
} from './pipes';
import { TogglePasswordDirective } from './directives';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { LanguageEnum } from '@app/enums/language.enum';
import { httpTranslationLoader } from '@app/class/http-translate-loader';
import { HttpClient } from '@angular/common/http';
import { LanguageSelectorComponent } from '@shared/components/language-selector/language-selector.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxTweetModule } from 'ngx-tweet';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';

@NgModule({
  declarations: [
    LanguageSelectorComponent,
    ProgressGaugeComponent,
    ProjectCardComponent,
    TeamAvatarsComponent,
    OrganizationInfoRowComponent,
    SpinnerComponent,
    SdgListComponent,
    KeywordsListComponent,
    PasswordStrengthMeterComponent,
    DefaultAvatarComponent,
    RecaptchaComponent,
    ToastComponent,
    QrCodeComponent,
    ProjectLikesComponent,
    TogglePasswordDirective,
    WithDefaultValuePipe,
    DateRangePipe,
    ConvertToBytesPipe,
    MapEvidenceBannerSrcPipe,
    MapEvidenceTypeCodePipe,
    MapEvidenceTypeCodeColorPipe,
    ProjectDateRangePipe,
    QrCodeComponent,
    SafeHtmlPipe,
    CustomNumberPipe
  ],
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule.forRoot({
      defaultLanguage: LanguageEnum.EN_GB,
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslationLoader,
        deps: [HttpClient]
      }
    }),
    SyncfusionModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordStrengthMeterModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    QRCodeModule,
    NgxDocViewerModule,
    NgxTweetModule,
    PaginatorModule
  ],
  exports: [
    CommonModule,
    TranslateModule,
    SyncfusionModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordStrengthMeterModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    PaginatorModule,
    LanguageSelectorComponent,
    ProgressGaugeComponent,
    ProjectCardComponent,
    TeamAvatarsComponent,
    OrganizationInfoRowComponent,
    SpinnerComponent,
    SdgListComponent,
    KeywordsListComponent,
    DefaultAvatarComponent,
    PasswordStrengthMeterComponent,
    RecaptchaComponent,
    ToastComponent,
    QrCodeComponent,
    ProjectLikesComponent,
    NgxDocViewerModule,
    NgxTweetModule,
    TogglePasswordDirective,
    WithDefaultValuePipe,
    DateRangePipe,
    ConvertToBytesPipe,
    SafeHtmlPipe,
    MapEvidenceBannerSrcPipe,
    MapEvidenceTypeCodePipe,
    MapEvidenceTypeCodeColorPipe,
    ProjectDateRangePipe,
    CustomNumberPipe
  ],
  providers: [KeyValuePipe]
})
export class SharedModule {
  constructor(@Inject(TranslateService) private translateService: TranslateService) {
    this.translateService.addLangs(Object.values(LanguageEnum));
    this.translateService.use(LanguageEnum.EN_GB);
  }
}
