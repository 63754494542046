<span
  *ngIf="userProfile?.imageUrl && userProfile?.imageUrl !== ''; else default"
  class="user-avatars_image"
  [style.background-image]="'url(' + userProfile?.imageUrl + ')'"
  data-bs-toggle="tooltip"
  title="{{ userProfile?.firstName }} {{ userProfile?.lastName }}"
></span>

<ng-template #default>
  <span
    class="user-avatars_default-avatar"
    [ngClass]="color"
    data-bs-toggle="tooltip"
    title="{{ userProfile?.firstName }} {{ userProfile?.lastName }}"
    >{{ initials }}</span
  >
</ng-template>
