import { Component, Input, OnInit } from '@angular/core';
import { TAKE_TOP_ITEMS } from '@app/constants';
import { IKeyword } from '@app/models';

@Component({
  selector: 'app-keywords-list',
  templateUrl: './keywords-list.component.html',
  styleUrls: ['./keywords-list.component.scss']
})
export class KeywordsListComponent implements OnInit {
  totalItemNumber!: number;
  excessItems!: number;
  keywordItems!: IKeyword[];

  @Input() displayLimit!: boolean;

  @Input() set keywords(value: IKeyword[]) {
    this.totalItemNumber = value.length;
    this.keywordItems = this.displayLimit ? value.slice(0, TAKE_TOP_ITEMS.keywords) : value;
    this.excessItems = this.totalItemNumber - this.keywordItems.length;
  }

  get keywordsExcessItems(): string {
    return this.excessItems ? `+${this.excessItems}` : '';
  }

  constructor() {}

  ngOnInit(): void {}
}
