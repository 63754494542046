import { Pipe, PipeTransform } from '@angular/core';
import { EVIDENCE_TYPE_BANNERS } from '@app/constants';
import { EvidenceType } from '@app/enums';
import { IEvidence } from '@app/models';
import { mapFileUploadImageSrc } from '@app/utils';

@Pipe({
  name: 'mapEvidenceBannerSrc'
})
export class MapEvidenceBannerSrcPipe implements PipeTransform {
  transform(value: IEvidence): string {
    let imgName = EVIDENCE_TYPE_BANNERS.other;

    if (value.type === EvidenceType.FreeText) {
      imgName = EVIDENCE_TYPE_BANNERS.freeText;
    } else if (value.type === EvidenceType.RcukData) {
      imgName = EVIDENCE_TYPE_BANNERS.rcukData;
    } else if (value.type === EvidenceType.WebClipping) {
      imgName = EVIDENCE_TYPE_BANNERS.webClipping;
    } else if (value.type === EvidenceType.Tweet) {
      imgName = EVIDENCE_TYPE_BANNERS.twitter;
    } else if (value.type === EvidenceType.FileUpload) {
      imgName = mapFileUploadImageSrc(value.fileInfo?.extension || '');
    } else if (value.type === EvidenceType.SymplecticElements) {
      imgName = EVIDENCE_TYPE_BANNERS.symplecticElements;
    } else if (value.type === EvidenceType.Email) {
      imgName = EVIDENCE_TYPE_BANNERS.email;
    }

    const imgUrl =
      value.type === EvidenceType.FileUpload ? imgName : `/assets/images/evidence/${imgName}`;
    return imgUrl;
  }
}
