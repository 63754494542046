export function constrainStringLength(str: string, limit: number): string {
  return str?.length > limit
    ? str?.substring(0, limit).replace(/.$/, '...') // replaces last char with ellipsis
    : str;
}

export function uniqueArrayOfObjectsById<T extends { id: string }>(array: T[]): T[] {
  return array.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj.id).indexOf(obj.id) === pos;
  });
}
