import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'projectDateRange'
})
export class ProjectDateRangePipe implements PipeTransform {
  transform(value: { startDate: string; endDate: string }): string {
    let displayValue = '';

    if (value.startDate && value.endDate) {
      const start = formatDate(value.startDate, 'mediumDate', 'en-us');
      const end = formatDate(value.endDate, 'mediumDate', 'en-us');

      displayValue = `${start} to ${end}`;
    } else if (value.startDate && !value.endDate) {
      const start = formatDate(value.startDate, 'mediumDate', 'en-us');

      displayValue = `${start} to -`;
    } else if (!value.startDate && value.endDate) {
      const end = formatDate(value.endDate, 'mediumDate', 'en-us');

      displayValue = `- to ${end}`;
    }

    return displayValue;
  }
}
