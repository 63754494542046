export enum PageType {
  None,
  Article,
  Audio,
  Chart,
  Discussion,
  Document,
  Download,
  Error,
  Event,
  FrontPage,
  Post,
  Game,
  Image,
  Job,
  Location,
  Other,
  Person,
  Product,
  Profile,
  Recipe,
  ReviewsList,
  Serp,
  Video,
  Faq,
  File,
  Organization
}
