import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectSpinnerCounter } from '@store/selectors';
import {
  AuthService,
  AUTH_QUERY_PARAMS,
  GoogleAnalyticsService,
  MaintenanceService
} from '@app/services';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { merge, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { META_PROPERTIES, PAGE_DESCRIPTIONS, PAGE_TITLES } from '@app/constants';
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  spinnerCounter$: Observable<number>;
  queryStringParams: URLSearchParams;

  private autoLogin!: boolean;
  private isLoggedIn!: boolean;
  private stop$ = new Subject<void>();

  constructor(
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    public analyticsService: GoogleAnalyticsService, // Prevents tree shaking of this service
    private store: Store,
    private metaService: Meta,
    private titleService: Title,
    private maintenanceService: MaintenanceService
  ) {
    this.queryStringParams = new URLSearchParams(window.location.search);
    this.spinnerCounter$ = this.store.pipe(select(selectSpinnerCounter));
    this.autoLogin = !!this.queryStringParams.get(AUTH_QUERY_PARAMS.AUTOLOGIN);
  }

  ngOnInit(): void {
    // Set under maintanence page active if it necessary
    this.maintenanceService.setUnderMaintenance(false);

    this.authService.isLoggedIn$
      .pipe(takeUntil(this.stop$))
      .subscribe((isLoggedIn) => (this.isLoggedIn = isLoggedIn));

    if (this.autoLogin && !this.isLoggedIn) {
      this.authService.signIn();
    }

    const onNavigationEnd = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );

    merge(onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data)
      )
      .subscribe((event) => {
        const title = event[META_PROPERTIES.TITLE];
        if (title) {
          this.titleService.setTitle(`${PAGE_TITLES.DEFAULT} - ${title}`);
        }

        const description = event[META_PROPERTIES.DESCRIPTION] || PAGE_DESCRIPTIONS.DEFAULT;
        if (description) {
          this.metaService.addTag({
            name: META_PROPERTIES.DESCRIPTION,
            content: description
          });
        }

        const author = event[META_PROPERTIES.AUTHOR];
        if (author) {
          this.metaService.addTag({
            name: META_PROPERTIES.AUTHOR,
            content: author
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
