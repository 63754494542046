import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customNumber'
})
export class CustomNumberPipe implements PipeTransform {
  transform(num: number, args?: number): string {
    if (isNaN(num) || !num) {
      return '0';
    }

    const devider = num > 9999 && num < 1000000 ? 100 : 100000;
    const suffix = num > 9999 && num < 1000000 ? 'k' : 'm';

    if (num <= 9999) {
      return num.toString();
    } else {
      const projectLikes = Math.floor(num / devider)
        .toString()
        .split('');
      const lastDigit = projectLikes.splice(projectLikes.length - 1)[0];

      if (lastDigit !== '0') {
        projectLikes.push('.', lastDigit);
      }

      return projectLikes.join('') + suffix;
    }
  }
}
