import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ScrollToService {
  constructor() {}

  private _scrollTo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get getScrollTo(): Observable<boolean> {
    return this._scrollTo.asObservable();
  }

  set setScrollTo(value: boolean) {
    this._scrollTo.next(value);
  }
}
