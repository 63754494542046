<button
  type="button"
  (click)="isLikeButtonActive ? toggleLikedProject() : ''"
  class="
    btn btn-liked-project
    d-flex
    justify-content-center
    align-items-center
    font-type-type-4
    liked-count
  "
>
  <span *ngIf="!isProcessingLike">
    <i class="me-2" [ngClass]="isLikedProject ? 'icon icon-heart-active' : 'icon icon-heart'"></i>
  </span>
  <div class="heart-loader-container ms-2 me-3" *ngIf="isProcessingLike">
    <div class="heart-loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  <span>{{ this.likesCount | customNumber }}</span>
</button>
