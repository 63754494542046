import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { Action, select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { selectBreadcrumbList } from '@store/selectors';
import { IBreadcrumb } from '@app/models';
import { uniqueArrayOfObjectsById } from '@app/utils';
import { BREADCRUMB_PATH_IDS, BREADCRUMB_PATH_LABELS } from '@app/constants';
import * as BreadcrumbActions from '../actions/breadcrumb.actions';

@Injectable()
export class BreadcrumbsEffects {
  private breadcrumbList!: IBreadcrumb[];

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store,
    private activatedRoute: ActivatedRoute
  ) {
    this.store.pipe(select(selectBreadcrumbList)).subscribe((val) => {
      this.breadcrumbList = val;
    });
  }

  buildInitialBreadcrumbs$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(BreadcrumbActions.buildInitialBreadcrumbs),
      switchMap(() =>
        of(this.buildBreadcrumb(this.activatedRoute.root)).pipe(
          map((breadcrumbList) => BreadcrumbActions.updateBreadcrumbList({ breadcrumbList }))
        )
      )
    )
  );

  refineBreadcrumbs$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(BreadcrumbActions.refineBreadcrumbs),
      switchMap((action) =>
        this.router.events.pipe(
          filter((event) => event instanceof NavigationEnd),
          distinctUntilChanged(),
          map(() => {
            return BreadcrumbActions.updateBreadcrumbList({
              breadcrumbList: this.refineBreadcrumbs(this.breadcrumbList, this.activatedRoute.root)
            });
          })
        )
      )
    )
  );

  private buildBreadcrumb(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: IBreadcrumb[] = []
  ): IBreadcrumb[] {
    const label: string = route.routeConfig
      ? route.routeConfig?.data?.breadcrumb
      : BREADCRUMB_PATH_LABELS.HOME;
    const id: string = route.routeConfig ? route.routeConfig?.data?.id : BREADCRUMB_PATH_IDS.HOME;
    const nextUrl: string = this.generateUrl(url, route);
    const breadcrumb = {
      id,
      label,
      url: nextUrl
      // queryParams: {}   // commented out because of the search updates, please don't remove for now
    };
    const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];

    if (route.firstChild) {
      return this.buildBreadcrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }

    // commented out because of the search updates, please don't remove for now
    // Getting query params of the current path if have any
    // route.queryParams.pipe(take(1)).subscribe((params) => {
    //   newBreadcrumbs[newBreadcrumbs.length - 1].queryParams = params;
    // });

    return newBreadcrumbs;
  }

  private refineBreadcrumbs(
    currentBreadcrumbs: IBreadcrumb[],
    route: ActivatedRoute
  ): IBreadcrumb[] {
    const newBreadcrumbs = this.buildBreadcrumb(route);
    currentBreadcrumbs = uniqueArrayOfObjectsById<IBreadcrumb>([
      ...currentBreadcrumbs,
      ...newBreadcrumbs
    ]);
    // Finding the index of the redirected path which is the last item in newBreadcrumbs
    // In order to clear all breadcrumb items after that point
    const index = currentBreadcrumbs.findIndex(
      (element) => element.id === newBreadcrumbs[newBreadcrumbs.length - 1].id
    );
    return currentBreadcrumbs.slice(0, index + 1);
  }

  private generateUrl(url: string, route: ActivatedRoute): string {
    let path = route.routeConfig?.path || '';
    const lastRouteParts = path.split('/');
    const isDynamicRoute = lastRouteParts.filter((p) => {
      return p.startsWith(':');
    });

    if (lastRouteParts.length && isDynamicRoute.length) {
      const paramNames = lastRouteParts.map((p) => p.split(':')[1]);

      route.params.subscribe((value) => {
        const params = paramNames.map((param) => value[param]);
        path = path?.replace(lastRouteParts.join('/'), params.join('/'));
      });
    }

    return `${url}${path}/`;
  }
}
