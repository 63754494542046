import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IAccount } from '@app/models';

@Component({
  selector: 'app-default-avatar',
  templateUrl: './default-avatar.component.html',
  styleUrls: ['./default-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultAvatarComponent implements OnInit {
  @Input() userProfile!: IAccount | null;

  get initials(): string {
    const firstName = this.userProfile?.firstName ? this.userProfile?.firstName[0] : '';
    const lastName = this.userProfile?.lastName ? this.userProfile?.lastName[0] : '';

    return firstName + lastName;
  }

  get color(): string {
    return `random-color-${Math.floor(Math.random() * 6) + 1}`;
  }

  constructor() {}

  ngOnInit(): void {}
}
