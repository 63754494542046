<div class="gauge-container">
  <ejs-circulargauge id="" width="100" height="100">
    <e-axes>
      <e-axis
        minimum="0"
        maximum="100"
        radius="100%"
        startAngle="200"
        endAngle="160"
        [majorTicks]="{ height: 0 }"
        [minorTicks]="{ height: 0 }"
        [lineStyle]="{ width: 0 }"
        [labelStyle]="{ font: { size: '0px' } }"
      >
        <e-ranges>
          <e-range
            start="0"
            end="{{ progress }}"
            radius="100%"
            startWidth="10"
            endWidth="10"
            color="{{ progressColor }}"
            roundedCornerRadius="1"
          ></e-range>
        </e-ranges>
        <e-pointers>
          <e-pointer type="RangeBar" radius="0%"> </e-pointer>
        </e-pointers>
        <e-annotations>
          <e-annotation angle="0" radius="25%" zIndex="1">
            <ng-template #content>
              <div class="progress-percent">{{ progressPercent }}</div>
            </ng-template>
          </e-annotation>
        </e-annotations>
      </e-axis>
    </e-axes>
  </ejs-circulargauge>
</div>
