import { Component, Input } from '@angular/core';
import { AVATARS_TO_DISPLAY } from '@app/constants';
import { IAccount } from '@app/models';

@Component({
  selector: 'app-team-avatars',
  templateUrl: './team-avatars.component.html',
  styleUrls: ['./team-avatars.component.scss']
})
export class TeamAvatarsComponent {
  @Input() label!: string;
  @Input() team!: IAccount[];
  @Input() avatarsCount: number = AVATARS_TO_DISPLAY;
  @Input() followersCount?: number;

  get userProfileList(): IAccount[] {
    return this.team.slice(0, this.avatarsCount);
  }

  get count(): number {
    return this.followersCount || this.team.length;
  }
}
