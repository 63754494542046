<div class="d-flex flex-wrap align-items-center justify-content-center">
  <div *ngFor="let sdg of sdgDictionary" class="sdg sdg-{{ sdg.id }}"></div>
  <div
    [ngClass]="{
      'excess-items': true,
      'align-self-center': true,
      empty: !sdgDictionary?.length
    }"
  >
    {{ excessItemsMessage }}
  </div>
</div>
