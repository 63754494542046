import { Component, Input } from '@angular/core';
import { CONTACT_METHOD_MAP } from '@app/constants';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent {
  @Input() qrData!: string;
  @Input() qrType!: string;

  readonly CONTACT_METHOD_MAP = CONTACT_METHOD_MAP;

  get modalTitle(): string {
    return CONTACT_METHOD_MAP[this.qrType];
  }
}
