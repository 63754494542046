<header class="main-container bg-white px-0 px-md-5">
  <nav class="navbar navbar-expand-md navbar-white py-0 navbar-light mx-md-4">
    <div class="col-12 col-md-2 navbar-brand d-flex align-items-center mt-md-0 mt-4 px-4 px-md-0">
      <div class="col-10">
        <img class="vv-hivve-logo navbar-brand" id="mainLogo" [src]="logoSrc" alt="Track Impact" />
      </div>
      <div class="col-1 d-sm-block d-md-none">
        <app-private-top-navbar *ngIf="isLoggedIn$ | async"></app-private-top-navbar>
      </div>
      <div class="col text-end">
        <button
          class="navbar-toggler border-0"
          type="button"
          (click)="isCollapsed = !isCollapsed"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
        >
          <i class="icon icon-list-charcoal"></i>
        </button>
      </div>
    </div>
    <div class="col-12 col-md-10 mt-md-0 mt-4">
      <app-navigation [isCollapsed]="isCollapsed"></app-navigation>
    </div>
  </nav>
</header>
