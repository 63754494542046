<div class="lead-organization d-flex align-items-center">
  <div
    class="organization-avatar"
    id="organizationAvatar"
    [style.background-image]="organizationImageUrl"
  ></div>
  <div class="organization-info w-100">
    <div
      class="lead-label mt-3"
      id="leadLabel"
      [ngStyle]="{ color: getDefaultLeadBoxColor(), 'border-color': getDefaultLeadBoxColor() }"
    >
      Lead
    </div>
    <h4
      class="name card-title d-inline-block"
      id="organizationName"
      [ngStyle]="{ color: getDefaultColor() }"
    >
      {{ organization?.name }}
    </h4>
    <div
      class="country-code projects-count"
      id="organizationInfo"
      [ngStyle]="{ color: getDefaultColor() }"
    >
      {{ getOrganizationLocation() }}
      <i [ngClass]="{ 'ps-custom': getOrganizationLocation() }">•</i>Active Projects:
      {{ getOrganizationActiveProjectsCount() }}
    </div>
  </div>
</div>
