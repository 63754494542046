import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { APP_ROUTES } from '@app/constants';
import { GoogleAnalyticsEvent } from '@app/models';

// This is the global Analytics function
// It is being injected by the script at global level
// @see appendGoogleScriptTag
declare const gtag: (...args: unknown[]) => void;

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {
  static readonly allowedPages: string[] = [
    APP_ROUTES.HOME,
    APP_ROUTES.PROJECT_SEARCH,
    APP_ROUTES.PROJECT
  ];

  navigationEndEvents$!: Observable<NavigationEnd>;
  isEnabled = false;

  constructor(public router: Router) {
    this.isEnabled = environment.googleAnalytics.enabled;

    this.initializeService();
  }

  static appendGoogleScriptTag(): void {
    const googleScriptEl: HTMLScriptElement = Object.assign(document.createElement('script'), {
      src: environment.googleAnalytics.src,
      async: true,
      type: 'text/javascript',
      charset: 'utf-8'
    });
    const googleCodeScript: HTMLScriptElement = Object.assign(document.createElement('script'), {
      innerText: environment.googleAnalytics.script,
      type: 'text/javascript',
      charset: 'utf-8'
    });

    document.head.append(googleScriptEl);
    document.head.append(googleCodeScript);
  }

  static byNavigationEnd(event: Event): boolean {
    return event instanceof NavigationEnd;
  }

  static byAllowedRoute(event: Event): boolean {
    if (!(event instanceof NavigationEnd)) {
      return false;
    }

    const { urlAfterRedirects } = event;

    return GoogleAnalyticsService.allowedPages.some((allowedPath: string) =>
      urlAfterRedirects.match(allowedPath)
    );
  }

  initializeService(): void {
    if (this.isEnabled) {
      GoogleAnalyticsService.appendGoogleScriptTag();

      this.navigationEndEvents$ = this.router.events.pipe(
        filter((e) => GoogleAnalyticsService.byNavigationEnd(e)),
        filter((e) => GoogleAnalyticsService.byAllowedRoute(e))
      ) as Observable<NavigationEnd>;

      this.navigationEndEvents$.subscribe((navEndEv: NavigationEnd) =>
        this.logNavigationEndEvent(navEndEv)
      );

      console.log('Google Analytics Enabled: Service Instantiated!');
    } else {
      Object.assign(window, { [`ga-disable-${environment.googleAnalytics.gtag}`]: true });

      console.log('Google Analytics Disabled');
    }
  }

  logNavigationEndEvent(navigationEnd: NavigationEnd): void {
    const { urlAfterRedirects } = navigationEnd;
    const opts = { page_path: urlAfterRedirects };

    return gtag('config', environment.googleAnalytics.gtag, opts);
  }

  // https://developers.google.com/analytics/devguides/collection/gtagjs/sending-data#send_data_with_the_event_command
  logEvent(eventName: string, event?: GoogleAnalyticsEvent): void {
    return gtag('event', eventName, event);
  }
}
