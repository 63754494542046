import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '@app/services/auth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly SCAN_API_URL = 'cloudstoragesecapp.com';
  private readonly AUTH_HEADER = 'Authorization';

  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.indexOf(this.SCAN_API_URL) !== -1) {
      return next.handle(req);
    }

    if (!req.headers.has('Content-Type')) {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
      });
    }

    return this.authService.accessIdToken$.pipe(
      switchMap((jwtToken) => {
        const authReq = req.clone({
          headers: req.headers.set(this.AUTH_HEADER, `Bearer ${jwtToken}`)
        });
        return next.handle(authReq);
      })
    );
  }
}
