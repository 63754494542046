export enum PageLayout {
  Grid = 'Grid',
  List = 'List'
}

export enum SortingField {
  Name = 0,
  Date = 1
}

export enum SortingOrder {
  Asc = 0,
  Desc = 1
}

export enum FilterGroups {
  impactAreas = 'impactAreas',
  sdgs = 'sdgs',
  keywords = 'keywords',
  organizations = 'organizations'
}
