const typeCache: Set<string> = new Set<string>();

export function registerAction<T extends string>(actionName: T): T {
  if (typeCache.has(actionName)) {
    throw new Error(`Action type "${actionName}" is not unique`);
  }

  typeCache.add(actionName);
  return actionName;
}
