export const PAGE_TITLES = {
  DEFAULT: 'TrackImpact.org',
  HOME: 'Home',
  PROJECTS: 'Projects',
  PRIVACY_POLICY: 'Privacy Policy',
  TERMS_CONDITIONS: 'Terms and Conditions',
  EULA: 'End User License Agreement',
  MY_ACCOUNT: 'My Account',
  MY_ACCOUNT_PROFILE: 'My Account - Profile',
  MY_ACCOUNT_NOTIFICATIONS: 'My Account - Notifications',
  MY_ACCOUNT_SOCIAL_NETWORKS: 'My Account - Social Networks',
  MY_ACCOUNT_PROJECTS: 'My Account - Projects'
};

export const PAGE_DESCRIPTIONS = {
  DEFAULT: 'Creating a global impact-conscious community today for a sustainable tomorrow'
};

export const META_PROPERTIES = {
  TITLE: 'title',
  AUTHOR: 'author',
  DESCRIPTION: 'description'
};
