import { Injectable } from '@angular/core';
import FileSaver from 'file-saver';

@Injectable()
export class FileSaverService {
  constructor() {}

  saveFileRawBytes(fileName: string, fileData: Uint8Array | string, fileMimeType?: string): void {
    if (fileMimeType) {
      FileSaver.saveAs(new Blob([fileData], { type: fileMimeType }), fileName);
    } else {
      FileSaver.saveAs(new Blob([fileData]), fileName);
    }
  }

  saveFile(fileName: string, fileData: Blob | string): void {
    FileSaver.saveAs(fileData, fileName);
  }
}
