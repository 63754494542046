import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { PRODUCT_NAME } from '@app/constants';
import { IAccount, IAppClient } from '@app/models';
import { AuthService } from '@app/services';
import { environment } from '@env';
import { select, Store } from '@ngrx/store';
import { selectUserDetails } from '@store/selectors';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-private-top-navbar',
  templateUrl: './private-top-navbar.component.html',
  styleUrls: ['./private-top-navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrivateTopNavbarComponent implements OnInit {
  @Input() isCollapsed = false;
  @Output() toggleEvent = new EventEmitter<boolean>();

  apps: IAppClient[] = [];
  userDetails!: IAccount | null;
  environment = environment;
  impactTrackerPlus = PRODUCT_NAME.IMPACT_TRACKER_PLUS;

  private MIN_WIDTH_SIZE = 576;
  private MAX_WIDTH_SIZE = 991.98;

  private userDetails$: Observable<IAccount | null>;
  private stop$ = new Subject<void>();

  constructor(private authService: AuthService, private store: Store) {
    this.userDetails$ = this.store.pipe(select(selectUserDetails));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const width = (event.target as Window).innerWidth;
    if (width && width >= this.MIN_WIDTH_SIZE && width <= this.MAX_WIDTH_SIZE) {
      this.isCollapsed = true;
      this.toggleEvent.emit(this.isCollapsed);
    }
  }

  onToggle(): void {
    this.isCollapsed = !this.isCollapsed;
    this.toggleEvent.emit(this.isCollapsed);
  }

  async ngOnInit(): Promise<void> {
    this.apps = await this.authService.loadApps();

    this.userDetails$.pipe(takeUntil(this.stop$)).subscribe((userDetails) => {
      this.userDetails = userDetails;
    });
  }

  async signOut(): Promise<void> {
    await this.authService.signOut();
  }

  // disableAppForNonLicencedAccount(appClient: IAppClient): boolean {
  //   if (appClient.client_name === PRODUCT_NAME.IMPACT_TRACKER && !this.userDetails?.isLicensed) {
  //     return true;
  //   }

  //   return false;
  // }
}
