<password-strength-meter [password]="password"></password-strength-meter>
<div class="characters-label">{{ password.length }} {{ charactersLabel }}</div>
<div class="password-requirement-labels">
  <div class="row d-flex justify-content-sm-start">
    <span
      class="col-auto"
      [class.red-label]="passwordControl.errors?.minlength || !password.length"
      [class.green-lable]="!passwordControl.errors?.minlength && password.length"
    >
      <i *ngIf="passwordControl.errors?.minlength || !password.length" class="icon icon-x-red"></i>
      <i
        *ngIf="!passwordControl.errors?.minlength && password.length"
        class="icon icon-check-green"
      ></i>
      Min length 8
    </span>
    <span
      class="col-auto"
      [class.red-label]="passwordControl.errors?.invalidLowerCase || !password.length"
      [class.green-label]="!passwordControl.errors?.invalidLowerCase && password.length"
    >
      <i
        *ngIf="passwordControl.errors?.invalidLowerCase || !password.length"
        class="icon icon-x-red"
      ></i>
      <i
        *ngIf="!passwordControl.errors?.invalidLowerCase && password.length"
        class="icon icon-check-green"
      ></i>
      Lower case
    </span>
    <span
      class="col-auto"
      [class.red-label]="passwordControl.errors?.invalidUpperCase || !password.length"
      [class.green-label]="!passwordControl.errors?.invalidUpperCase && password.length"
    >
      <i
        *ngIf="passwordControl.errors?.invalidUpperCase || !password.length"
        class="icon icon-x-red"
      ></i>
      <i
        *ngIf="!passwordControl.errors?.invalidUpperCase && password.length"
        class="icon icon-check-green"
      ></i>
      Upper case
    </span>
    <span
      class="col-auto"
      [class.red-label]="passwordControl.errors?.invalidNumberDigit || !password.length"
      [class.green-label]="!passwordControl.errors?.invalidNumberDigit && password.length"
    >
      <i
        *ngIf="passwordControl.errors?.invalidNumberDigit || !password.length"
        class="icon icon-x-red"
      ></i>
      <i
        *ngIf="!passwordControl.errors?.invalidNumberDigit && password.length"
        class="icon icon-check-green"
      ></i>
      Numbers
    </span>
    <span
      class="col-auto"
      [class.red-label]="passwordControl.errors?.invalidSpecialCharacter || !password.length"
      [class.green-label]="!passwordControl.errors?.invalidSpecialCharacter && password.length"
    >
      <i
        *ngIf="passwordControl.errors?.invalidSpecialCharacter || !password.length"
        class="icon icon-x-red"
      ></i>
      <i
        *ngIf="!passwordControl.errors?.invalidSpecialCharacter && password.length"
        class="icon icon-check-green"
      ></i>
      Symbols
    </span>
  </div>
</div>
