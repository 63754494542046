import { Component, Input, OnInit } from '@angular/core';
import {
  COUNTRY_CODES,
  DEFAULT_LEAD_ORGANIZATION_BOX_COLOR,
  DEFAULT_ORGANIZATION_ACTIVE_PROJECTS_COUNT,
  ORGANIZATION_TEXT_WHITE_COLOR,
  ORGANIZATION_TEXT_WHITE_COLOR_SHORT,
  DEFAULT_ORGANIZATION_TEXT_COLOR,
  DEFAULT_ORGANIZATION_IMAGE
} from '@app/constants';
import { IOrganization } from '@app/models';

@Component({
  selector: 'app-organization-info-row',
  templateUrl: './organization-info-row.component.html',
  styleUrls: ['./organization-info-row.component.scss']
})
export class OrganizationInfoRowComponent implements OnInit {
  @Input() organization!: IOrganization | null;
  @Input() isProjectLeadOrganizationBox!: boolean;

  organizationWhiteColor = ORGANIZATION_TEXT_WHITE_COLOR;
  organizationWhiteColorShort = ORGANIZATION_TEXT_WHITE_COLOR_SHORT;
  organizationBoxColor = DEFAULT_LEAD_ORGANIZATION_BOX_COLOR;
  organizationTextColor = DEFAULT_ORGANIZATION_TEXT_COLOR;

  countryCodes = COUNTRY_CODES;

  get organizationImageUrl(): string {
    return this.organization?.portraitLogo
      ? `url("${this.organization.portraitLogo}")`
      : `url("${DEFAULT_ORGANIZATION_IMAGE}")`;
  }

  getDefaultColor(): string {
    if (
      this.organization?.colour &&
      this.isProjectLeadOrganizationBox &&
      this.organization?.colour?.toLowerCase() !== this.organizationWhiteColor &&
      this.organization?.colour?.toLowerCase() !== this.organizationWhiteColorShort
    ) {
      return this.organizationWhiteColor;
    } else {
      return this.organizationTextColor;
    }
  }

  getDefaultLeadBoxColor(): string {
    if (
      this.organization?.colour &&
      this.isProjectLeadOrganizationBox &&
      this.organization?.colour?.toLowerCase() !== this.organizationWhiteColor &&
      this.organization?.colour?.toLowerCase() !== this.organizationWhiteColorShort
    ) {
      return this.organizationWhiteColor;
    } else {
      return this.organizationBoxColor;
    }
  }

  getOrganizationLocation(): string {
    return this.organization?.countryCode ? this.countryCodes[this.organization?.countryCode] : '';
  }

  getOrganizationActiveProjectsCount(): number | undefined {
    return Number(this.organization?.id) !== 0
      ? this.organization?.activeProjectsCount
      : DEFAULT_ORGANIZATION_ACTIVE_PROJECTS_COUNT;
  }

  constructor() {}

  ngOnInit(): void {}
}
