<div class="maintenance-wrapper">
  <div class="maintenance-wrapper-content">
    <div class="d-flex d-xl-none justify-content-center">
      <div class="trackimpact-logo"></div>
    </div>
    <div class="d-block d-xl-flex justify-content-center align-items-center">
      <div class="text">
        <h1 class="heading text-uppercase text-bold mb-5">Under Maintenance</h1>
        <div class="text-content">
          <p class="mb-0">Yes you're in the right palce, but we are runnig late.</p>
          <p>Our sincere apologies.</p>
          <p class="mb-0">Go and grab a cup of coffee and we'll see you shortly</p>
          <p>with some exciting new features.</p>
        </div>
      </div>
      <div class="image-wrapper">
        <img
          class="maintenance-image"
          alt="trackimpact-logo"
          src="/assets/images/under_maintenance.png"
        />
      </div>
    </div>
    <div class="d-none d-xl-flex justify-content-end">
      <div class="trackimpact-logo"></div>
    </div>
  </div>
</div>
