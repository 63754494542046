export enum ContactMethod {
  email = 'email',
  whatsApp = 'whatsApp',
  phone = 'phone'
}

export enum UserProjectListType {
  followed = 'followed',
  collaboration = 'collaboration',
  liked = 'liked',
  owned = 'owned'
}
