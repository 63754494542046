export const INITIAL_AUTH_STATE = {
  isLoggedIn: false,
  username: null,
  id: null,
  email: null,
  region: null
};

export const AUTH_EVENT = {
  SIGN_IN: 'signIn',
  SIGN_OUT: 'signOut'
};

export const REDIRECT_TIMEOUT_MSG = 5;

export const AUTH_QUERY_PARAMS = {
  AUTOLOGIN: 'autologin'
};
