import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLayoutComponent, HomeLayoutComponent, MaintenanceComponent } from './layout';
import { APP_ROUTES } from '@app/constants';
import { MaintenanceGuard } from '@app/services';

const routes: Routes = [
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [MaintenanceGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () =>
          import('@modules/homepage/homepage.module').then((m) => m.HomepageModule)
      }
    ]
  },
  { path: 'legal', redirectTo: '', pathMatch: 'full' },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [MaintenanceGuard],
    children: [
      {
        path: APP_ROUTES.PROJECT,
        loadChildren: () =>
          import('@modules/single-project/single-project.module').then((m) => m.SingleProjectModule)
      },
      {
        path: 'search',
        loadChildren: () =>
          import('@modules/search-result/search-result.module').then((m) => m.SearchResultModule)
      },
      {
        path: 'my-account',
        redirectTo: '/my-account/projects',
        pathMatch: 'full'
      },
      {
        path: 'my-account',
        loadChildren: () =>
          import('@modules/my-account/my-account.module').then((m) => m.MyAccountModule)
      },
      {
        path: 'legal',
        loadChildren: () => import('@modules/legal/legal.module').then((m) => m.LegalModule)
      }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
