export const DISPLAY_MESSAGES = {
  ConfidentialInfo: 'This information is confidential.',
  NoDescription: 'This project does not have a summary available.',
  DefaultVerbiage: `Users can clearly show the benefits and impacts arising from projects to other
  researchers and scientists as well as the general public, businesses, policy makers
  and others interested in these challenges to inform...`
};

export const PROJECT_STATUS_LABELS = {
  Active: '• Active',
  Inactive: '• Inactive'
};

export const ERROR_CODES = {
  FILE_INFECTED: 'FILE_INFECTED'
};

export const ERROR_MESSAGES = {};
