<div [ngClass]="{ 'nav-item': inNavbar }">
  <a *ngIf="inNavbar; else button" id="language-dropdown" class="nav-link">
    {{ translateService.currentLang | translate }}
  </a>

  <ng-template #button>
    <button class="btn btn-sm btn-secondary">
      {{ translateService.currentLang | translate }}
    </button>
  </ng-template>

  <div aria-labelledby="language-dropdown" [ngClass]="menuClass">
    <button
      class="dropdown-item"
      *ngFor="let language of getAvailableLanguages()"
      (click)="translateService.use(language)"
    >
      {{ language | translate }}
    </button>
  </div>
</div>
