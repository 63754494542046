import { createReducer, on } from '@ngrx/store';
import { ISpinnerState } from '@store/state';

import * as SpinnerActions from '../actions/spinner.actions';

export const initialState: ISpinnerState = {
  spinnerCounter: 0
};

export const spinnerReducer = createReducer(
  initialState,
  on(SpinnerActions.showSpinner, (state) => ({
    ...state,
    spinnerCounter: state.spinnerCounter + 1
  })),
  on(SpinnerActions.hideSpinner, (state) => ({
    ...state,
    spinnerCounter: state.spinnerCounter > 0 ? state.spinnerCounter - 1 : 0
  }))
);
