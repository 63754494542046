import { ProjectSortBy, SortingField, SortingOrder } from '@app/enums';
import { ISelectItem, PagingParams } from '@app/models';

export const SEARCH_PAGESIZE_OPTIONS: number[] = [12, 18, 24, 30];

export const SORT_BY_OPTIONS: ISelectItem[] = [
  {
    label: 'Newest',
    value: {
      field: SortingField.Date,
      order: SortingOrder.Asc
    }
  },
  {
    label: 'Oldest',
    value: {
      field: SortingField.Date,
      order: SortingOrder.Desc
    }
  },
  {
    label: 'Name (A-Z)',
    value: {
      field: SortingField.Name,
      order: SortingOrder.Asc
    }
  },
  {
    label: 'Name (Z-A)',
    value: {
      field: SortingField.Name,
      order: SortingOrder.Desc
    }
  }
];

export const PAGE_COUNT = 3;

export const FILTER_LABEL_MAP: { [key: string]: string } = {
  impactAreas: 'Impact Area',
  sdgs: 'SDGs',
  keywords: 'Keywords',
  organizations: 'Organisations'
};

export const DEFAULT_PAGING_PARAMS: PagingParams = {
  startIndex: 0,
  pageSize: SEARCH_PAGESIZE_OPTIONS[0],
  isDescending: true,
  orderByProperty: ProjectSortBy.DateAdded
};
