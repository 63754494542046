import { createReducer, on } from '@ngrx/store';
import { IBreadcrumbState } from '@store/state';
import { constrainStringLength } from '@app/utils';
import * as BreadcrumbActions from '../actions/breadcrumb.actions';
import { BREADCRUMB_MAX_LABEL_LENGTH } from '@app/constants';

export const initialState: IBreadcrumbState = {
  breadcrumbList: []
};

export const breadcrumbReducer = createReducer(
  initialState,
  on(BreadcrumbActions.updateBreadcrumbList, (state, props) => ({
    ...state,
    breadcrumbList: props.breadcrumbList
  })),
  on(BreadcrumbActions.updateBreadcrumbItemLabel, (state, props) => ({
    ...state,
    breadcrumbList: state.breadcrumbList.map((item) => {
      if (item.id === props.breadcrumbId) {
        return {
          ...item,
          label: constrainStringLength(props.label, BREADCRUMB_MAX_LABEL_LENGTH)
        };
      }
      return item;
    })
  }))
);
