import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { MaintenanceService } from '../maintenance';
import { APP_ROUTES } from '@app/constants';

@Injectable()
export class MaintenanceGuard implements CanActivate {
  constructor(private maintenanceService: MaintenanceService, private router: Router) {}

  canActivate(): boolean {
    if (this.maintenanceService.isMaintenanceMode()) {
      this.router.navigate(['/maintenance']);
      return false;
    } else {
      return true;
    }
  }
}
