import { ActionReducerMap } from '@ngrx/store';
import { currentUserReducer, spinnerReducer, breadcrumbReducer } from '@store/reducers';
import { ICurrentUserState } from './current-user.state';
import { ISpinnerState } from './spinner.state';
import { IBreadcrumbState } from './breadcrumb.state';

export interface IAppState {
  currentUser: ICurrentUserState;
  spinner: ISpinnerState;
  breadcrumb: IBreadcrumbState;
}

export const appReducers: ActionReducerMap<IAppState> = {
  currentUser: currentUserReducer,
  spinner: spinnerReducer,
  breadcrumb: breadcrumbReducer
};
