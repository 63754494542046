<div class="modal fade" id="qrCodeModal" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content p-1">
      <div class="modal-body">
        <div class="text-end">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="text-center mt-5">
          <p class="font-type-type-4">
            {{ modalTitle }}
          </p>
          Scan this QR code to get in touch
          <qrcode
            *ngIf="qrData"
            [qrdata]="qrData || ''"
            [width]="173"
            [errorCorrectionLevel]="'M'"
            [elementType]="'svg'"
          ></qrcode>
        </div>
        <div class="text-center mb-5">
          <a
            *ngIf="qrType.toLowerCase() === CONTACT_METHOD_MAP.email.toLowerCase()"
            class="font-type-type-26"
            [href]="qrData || ''"
            >Send Mail</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
