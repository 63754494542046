import { TAKE_TOP_ITEMS } from '@app/constants';
import { ISdg } from '@app/models';

export function mapProjectSdgList(list: string[], limitDisplay?: boolean): ISdg[] {
  const result = limitDisplay ? list.slice(0, TAKE_TOP_ITEMS.sdgInHeadlines) : list;
  return result.map<ISdg>((item) => {
    const pair = item.split('-');
    return { id: pair[0].trim(), description: pair[1].trim() };
  });
}
